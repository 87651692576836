import React, { FC, useContext, useEffect, useState } from 'react'
import { PageTitle } from '@components/configs/PageTitle'
import SectionTitle from '@components/ui/sectionTitle'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import * as pageUtils from '@pages/auth/requests/__index.utils'
import { Dictionary } from 'typings/shared'
import RequestCard from '@components/ui/requestCard'
import Notification from '@components/ui/notification'
import { joinClasses } from '@utils/styles'
import ReactPaginate from '@components/ui/pagination'
import Loader from '@components/ui/loader'
import { Request } from '@services/models'
import { CustomDate } from '@services/models/shared.model'
import {
	IDENTIFIERS_OF_TN_REQUEST_TYPE,
	RequestPartialsEnum
} from '@services/constants'
import { setRequestListStore } from '@services/store/requestList'
import { useAppDispatch } from '@services/store'

enum FILTER_CONSTANTS {
	ALL = 'all',
	OPEN = 'open',
	PROCESSING = 'processing',
	CLOSED = 'closed',
	ACTIVE = 'active',
	CREATION_DATE = 'dateAdd',
	MODIFICATION_DATE = 'dateUpdate',
	CREATION_DATE_ASC = 'dateAdd_asc',
	CREATION_DATE_DESC = 'dateAdd_desc',
	MODIFICATION_DATE_ASC = 'dateUpdate_asc',
	MODIFICATION_DATE_DESC = 'dateUpdate_desc'
}

enum ORDER {
	ASC = 'ASC',
	DESC = 'DESC'
}

const Requests: FC = () => {
	const { pageData, authUser } = useContext(AppStateContext)
	const [requests, setRequests] = useState<Request[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [filter, setFilter] = useState<FILTER_CONSTANTS>(FILTER_CONSTANTS.ALL)
	const [sorter, setSorter] = useState<string>(
		FILTER_CONSTANTS.CREATION_DATE_DESC
	)
	const [tabFilter, setTabFilter] = useState<FILTER_CONSTANTS>(
		FILTER_CONSTANTS.ACTIVE
	)
	const [tempRequests, setTempRequests] = useState<Request[]>([])
	const [isInformationSent, setIsInformationSent] = useState<boolean>(false)
	const [requestcode, setRequestCode] = useState<Request['code']>()
	const dispatch = useAppDispatch()

	const fetchRequests = async () => {
		if (authUser) {
			try {
				// TODO: use a local storage for optimization ? or just rm this line
				const requestsList = await authUser.fetchRequests(
					false,
					[RequestPartialsEnum.PHONE_CALL, RequestPartialsEnum.EMAILS]
				)
				setRequests(requestsList)
				dispatch(
					setRequestListStore({
						fetching: false,
						fetched: true,
						requests: requestsList
					})
				)
			} catch (error: any) {
				console.error(error?.message)
			} finally {
				setTimeout(() => setIsLoading(false), 200)
			}
		}
	}

	useEffect(() => {
		fetchRequests().catch(console.error)
	}, [authUser])

	const onConsentValidated = (consentId) => {
		if (consentId) {
			setIsInformationSent(true)
		}
	}

	const Items = ({ currentItems }) => {
		return (
			<div className="items">
				{!!currentItems &&
					currentItems.map((request, key) => (
						<RequestCard
							request={request}
							pageAssets={pageData?.assets}
							requestTypes={pageData.requests}
							key={key}
							onConsentValidated={onConsentValidated}
							setRequestCode={setRequestCode}
							data-testid="requestcard"
						/>
					))}
			</div>
		)
	}

	const PaginatedItems = ({ itemsPerPage }) => {
		// We start with an empty list of items.
		const [currentItems, setProcessingItems] = useState<any>(null)
		const [pageCount, setPageCount] = useState(0)
		// Here we use item offsets; we could also use page offsets
		// following the API or data you're working with.
		const [itemOffset, setItemOffset] = useState(0)

		useEffect(() => {
			// Fetch items from another resources.
			const endOffset = itemOffset + itemsPerPage

			setProcessingItems(tempRequests.slice(itemOffset, endOffset))
			setPageCount(Math.ceil(tempRequests.length / itemsPerPage))
		}, [itemOffset, itemsPerPage, tempRequests.length])

		// Invoke when user clicks to request another page.
		const handlePageClick = (event) => {
			const newOffset = (event.selected * itemsPerPage) % tempRequests.length
			setItemOffset(newOffset)
		}

		return (
			<>
				<Items currentItems={currentItems} />
				<ReactPaginate
					hide={tempRequests.length <= itemsPerPage}
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel="&nbsp;"
					nextLabel="&nbsp;"
					pageClassName={pageUtils.classes.pager}
					pageLinkClassName={pageUtils.classes.pager}
					previousClassName={pageUtils.classes.pager}
					previousLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.simple,
						pageUtils.classes.previous
					])}
					nextClassName={pageUtils.classes.pager}
					nextLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.simple
					])}
					breakLabel="..."
					breakClassName={pageUtils.classes.pager}
					breakLinkClassName={pageUtils.classes.pager}
					containerClassName={pageUtils.classes.pagination}
					activeClassName={pageUtils.classes.currentPage}
					firstItemLabel="&nbsp;"
					lastItemLabel="&nbsp;"
					firstItemClassName={pageUtils.classes.pager}
					lastItemClassName={pageUtils.classes.pager}
					firstItemLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.double,
						pageUtils.classes.previous
					])}
					lastItemLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.double
					])}
					disabledClassName={pageUtils.classes.hidde}
				/>
			</>
		)
	}

	const displayFilter = (pageAssets: Dictionary) => (
		<>
			<div
				className={joinClasses([
					pageUtils.classes.filtersSection,
					`${
						filter === FILTER_CONSTANTS.ALL && !requests.length
							? pageUtils.classes.hidde
							: ''
					}`
				])}
			>
				{tabFilter == FILTER_CONSTANTS.ACTIVE && (
					<div
						className={pageUtils.classes.filters}
						style={{ marginRight: 30 }}
					>
						<label style={{ whiteSpace: 'nowrap' }}>
							<b>{formatStrapiText(pageAssets?.filter_label)}</b>
						</label>
						<select
							className={joinClasses([
								pageUtils.classes.inline,
								pageUtils.classes.select,
								'filter'
							])}
							onChange={handleChange}
							defaultValue={FILTER_CONSTANTS.ALL}
							value={filter}
						>
							<option value={FILTER_CONSTANTS.ALL}>
								{formatStrapiText(
									pageAssets?.page_myRequest_select_allStatuses
								)}
							</option>
							<option value={FILTER_CONSTANTS.OPEN}>
								{formatStrapiText(pageAssets?.page_myRequest_select_open)}
							</option>
							<option value={FILTER_CONSTANTS.PROCESSING}>
								{formatStrapiText(pageAssets?.page_myRequest_select_processing)}
							</option>
						</select>
					</div>
				)}
				<div className={pageUtils.classes.filters}>
					<label style={{ whiteSpace: 'nowrap' }}>
						<b>{formatStrapiText(pageAssets?.sort_label)}</b>
					</label>
					<select
						style={{ width: '100%' }}
						className={joinClasses([
							pageUtils.classes.inline,
							pageUtils.classes.select,
							'sort'
						])}
						onChange={handleChange}
						defaultValue={FILTER_CONSTANTS.CREATION_DATE_DESC}
					>
						<option value={FILTER_CONSTANTS.CREATION_DATE_DESC}>
							{formatStrapiText(
								pageAssets?.page_myRequest_select_creationDate_desc
							)}
						</option>
						<option value={FILTER_CONSTANTS.CREATION_DATE_ASC}>
							{formatStrapiText(
								pageAssets?.page_myRequest_select_creationDate_asc
							)}
						</option>
						<option value={FILTER_CONSTANTS.MODIFICATION_DATE_DESC}>
							{formatStrapiText(
								pageAssets?.page_myRequest_select_lastModificationDate_desc
							)}
						</option>
						<option value={FILTER_CONSTANTS.MODIFICATION_DATE_ASC}>
							{formatStrapiText(
								pageAssets?.page_myRequest_select_lastModificationDate_asc
							)}
						</option>
					</select>
				</div>
			</div>
			{!tempRequests.length && (
				<Notification
					hasHtml
					type="info"
					text={
						pageData.assets?.request_emptyFilter +
						' <b>' +
						`${getActiveFilterText(pageData.assets)}` +
						'</b>.'
					}
				/>
			)}
		</>
	)
	const getActiveFilterText = (pageAssets: Dictionary) => {
		if (tabFilter === FILTER_CONSTANTS.ACTIVE) {
			switch (filter) {
				case FILTER_CONSTANTS.ALL:
					return formatStrapiText(pageAssets?.page_myRequest_select_allStatuses)
				case FILTER_CONSTANTS.OPEN:
					return formatStrapiText(pageAssets?.page_myRequest_select_open)
				case FILTER_CONSTANTS.PROCESSING:
					return formatStrapiText(pageAssets?.page_myRequest_select_processing)
			}
		} else if (tabFilter === FILTER_CONSTANTS.CLOSED) {
			return formatStrapiText(pageAssets?.page_myRequest_select_closed)
		}
	}

	const displayTabFilter = (pageAssets: Dictionary) => {
		return (
			<div className={pageUtils.classes.filterTab}>
				<div
					className={joinClasses([
						tabFilter == FILTER_CONSTANTS.ACTIVE ? 'active' : ''
					])}
					onClick={() => handleTabFilterChange(FILTER_CONSTANTS.ACTIVE)}
				>
					{formatStrapiText(pageAssets?.page_myRequest_tabFilter_active)}
				</div>
				<div
					className={joinClasses([
						tabFilter == FILTER_CONSTANTS.CLOSED ? 'active' : ''
					])}
					onClick={() => handleTabFilterChange(FILTER_CONSTANTS.CLOSED)}
				>
					{formatStrapiText(pageAssets?.page_myRequest_tabFilter_closed)}
				</div>
			</div>
		)
	}

	const handleChange = (e) => {
		if (e.target.className.includes('filter')) {
			setFilter(e.target.value)
		} else if (e.target.className.includes('sort')) {
			setSorter(e.target.value)
		}
	}

	const handleTabFilterChange = (value) => {
		setTabFilter(value)
	}

	const applyFilter = (
		tabFilter: FILTER_CONSTANTS,
		filter: FILTER_CONSTANTS
	) => {
		let value = [...requests]
		let order: ORDER = ORDER.DESC

		// hide all Divulgation Mazout requests
		value =
			value.filter(
				(request) =>
					![
						`${IDENTIFIERS_OF_TN_REQUEST_TYPE.OIL_HEATING_DECLARATION_FORM}`,
						`${IDENTIFIERS_OF_TN_REQUEST_TYPE.FIREPLACE_DECLARATION}`,
						`${IDENTIFIERS_OF_TN_REQUEST_TYPE.SELF_RELIEF_WATER_COUNTER_FORM}`
					].includes(request.typeId)
			) || []

		// tabFilter (ACTIVE or CLOSED)
		value =
			value.filter((request) => {
				if (tabFilter == FILTER_CONSTANTS.CLOSED) {
					return request.status == (FILTER_CONSTANTS.CLOSED as string)
				} else {
					return request.status !== (FILTER_CONSTANTS.CLOSED as string)
				}
			}) || []

		// filter (if ACTIVE then check status OPEN or PROCESSING)
		if (
			tabFilter == FILTER_CONSTANTS.ACTIVE &&
			filter !== FILTER_CONSTANTS.ALL
		) {
			value =
				value.filter((request) => {
					return (
						request.status.toString() === filter.toString() &&
						!request.cancelReason
					)
				}) || []
		}

		if (
			sorter === FILTER_CONSTANTS.CREATION_DATE_ASC ||
			sorter === FILTER_CONSTANTS.MODIFICATION_DATE_ASC
		) {
			order = ORDER.ASC
		} else if (
			sorter === FILTER_CONSTANTS.CREATION_DATE_DESC ||
			sorter === FILTER_CONSTANTS.MODIFICATION_DATE_DESC
		) {
			order = ORDER.DESC
		}

		sortRequests(value, sorter, order)
	}

	const sortRequests = (
		requests: Request[],
		sorter: string,
		order: ORDER = ORDER.DESC
	) => {
		const datesSort: Set<string> = new Set([
			FILTER_CONSTANTS.CREATION_DATE_ASC,
			FILTER_CONSTANTS.MODIFICATION_DATE_ASC,
			FILTER_CONSTANTS.CREATION_DATE_DESC,
			FILTER_CONSTANTS.MODIFICATION_DATE_DESC
		])

		if (datesSort.has(sorter)) {
			const temp_sorter = sorter?.split('_')[0]

			if (order === ORDER.ASC) {
				setTempRequests(
					requests.sort((a, b) =>
						CustomDate.diff(a[temp_sorter], b[temp_sorter])
					)
				)
			} else {
				setTempRequests(
					requests.sort((a, b) =>
						CustomDate.diff(b[temp_sorter], a[temp_sorter])
					)
				)
			}

			return
		}
	}

	useEffect(() => {
		applyFilter(tabFilter, filter)
	}, [tabFilter, filter, requests, sorter])

	return (
		<>
			<PageTitle title={formatStrapiText(pageData?.title)} />
			<SectionTitle title={formatStrapiText(pageData?.title)} />
			{requests.length > 0 && displayTabFilter(pageData?.assets)}
			<section className={pageUtils.classes.section}>
				{isInformationSent && (
					<Notification
						showExitBtn
						text={`${pageData.assets?.myRequest_information_well_added_to_the_request} <b>${requestcode}</b>`}
						type="success"
						hasHtml
						onClickCancelBtn={() => {
							setIsInformationSent(false)
						}}
					/>
				)}

				{requests.length <= 0 && !isLoading && (
					<Notification
						type="info"
						text={pageData?.assets?.request_notificationEmptyMessage}
					/>
				)}
				{isLoading && <Loader text={pageData?.assets?.loading} />}
				{requests.length > 0 && (
					<>
						{displayFilter(pageData?.assets)}
						<PaginatedItems itemsPerPage={5} />
					</>
				)}
			</section>
		</>
	)
}

export default Requests
