import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories,
	Direction,
	REQUEST_CONFIGS_KEYS
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RadioInput from '@components/requestForms/inputs/radio.input'
import CheckboxInput from './inputs/checkbox.input'
import TextAreaInput from './inputs/textarea.inputs'
import SelectInput from './inputs/select.inputs'
import AddressInfoSection from '@components/ui/addressInfosSection'
import RequestFiles from '@components/ui/requestFiles'
import { formatStrapiText } from '@utils/methods'

type Classes = {
	coveredSearchPeriodContainer: string | undefined
	errorLabel: string
	rowSection: string | undefined
	button: string
	buttonContainer: string
	inputContainer: string
	dateAndTimeSection: string
	timeInputPadding: string
	adresseBlock: string
	checkbox2: string
	checkbox2Label: string
	checkbox2Container: string
	checkboxWrapper: string
	fileContainer: string
	fileHelperSection: string
	detailsContainer: string
	requestObjectWrapper: string
	collapseWrapper: string
}

const classes: Classes = makeClasses({
	button: {
		display: 'inline-block',
		padding: '10px 20px',
		fontSize: '13px',
		fontWeight: '500',
		color: Colors.white,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		MsTransition: 'all 0.35s ease',
		background: Colors.secondary,
		border: 'none',
		cursor: 'pointer',
		'&:hover': {
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2,
			color: Colors.white
		}
	},
	buttonContainer: {
		display: 'flex',
		width: 'fit-content',
		columnGap: '4px'
	},
	inputContainer: {
		marginBottom: '20px',
		display: 'block',
		width: '-webkit-fill-available',
		'& span': {
			color: Colors.black
		}
	},
	dateAndTimeSection: {
		display: 'grid',
		alignItems: 'center',
		gridTemplateColumns: '27%27%',
		columnGap: '1%',
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '49.5%49.5%'
		},
		[Breakpoints.maxWidth('sm')]: {
			gridTemplateColumns: '49.5%49.5%'
		}
	},
	coveredSearchPeriodContainer: {
		display: 'block',
		width: '40%'
	},
	rowSection: {
		display: 'flex',
		alignItems: 'end',
		columnGap: '1%',
		'& > div': {
			marginBottom: '20px'
		}
	},
	errorLabel: {
		color: Colors.errorRed,
		'& span': {
			color: Colors.errorRed + ' !important'
		}
	},
	adresseBlock: {
		border: `1px solid ${Colors.greyAlto}`,
		padding: '20px 25px',
		marginBottom: '10px'
	},
	checkbox2: {
		width: 'fit-content',
		position: 'absolute',
		verticalAlign: 'baseline',
		clip: 'rect(1px, 1px, 1px, 1px)'
	},
	checkbox2Label: {
		fontSize: '16px',
		padding: '0',
		color: Colors.lightBlack,
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: `1px solid ${Colors.lightGrey3}`,
			background: Colors.white,
			outline: 'none !important'
		},
		':after': {
			content: "''",
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: Colors.secondary,
			outline: 'none',
			opacity: '0',
			transition: 'all .15s ease-out'
		}
	},
	checkbox2Container: {
		'& input[value=true] ~ label:after': {
			opacity: '.99'
		}
	},
	checkboxWrapper: {
		display: 'block',
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '14px 12px'
	},
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	fileContainer: {
		'.blue-bullets-list': {
			ul: {
				padding: '0px 15px 0px 20px'
			}
		}
	},
	detailscontainer: {
		display: 'grid',
		alignItems: 'start',
		gridTemplateColumns: '60%30%',
		columnGap: '5%',
		[Breakpoints.maxWidth('sm')]: {
			gridTemplateColumns: '100%',
			'& img': {
				marginTop: '15px !important'
			}
		},

		fontSize: '16px',
		color: Colors.lightBlack
	},
	requestObjectWrapper: {
		'word-wrap': 'break-word',
		[Breakpoints.minWidth('md')]: {
			width: '45rem'
		}
	},
	collapseWrapper: {
		height: 'auto !important'
	}
})

const MunicipalEvaluationForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	//----------------------------------------------------------------
	//----------------------- INPUTS ---------------------------------
	//----------------------------------------------------------------

	const [addressLotOrSerialInput, setAddressLotOrSerialInput] =
		useState<RequestInputs>({
			name: 'addressLotOrSerial',
			label:
				pageAssets?.request_form_municipalEvaluation_addressLotOrSerial_label,
			labelKey: 'request_form_municipalEvaluation_addressLotOrSerial_label',
			value: 'not bound to an input',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const [applicantStatusInput, setApplicantStatusInput] =
		useState<RequestInputs>({
			name: 'applicantStatus',
			label: pageAssets?.request_form_municipalEvaluation_applicantStatus_label,
			labelKey: 'request_form_municipalEvaluation_applicantStatus_label',
			value: answers.applicantStatus?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const [showTheOtherInput, setShowTheOtherInput] = useState<boolean>(
		applicantStatusInput.value == '0'
	)

	const [provideMoreInfoInput, setProvideMoreInfoInput] =
		useState<RequestInputs>({
			name: 'provideMoreInfo',
			label: pageAssets?.request_form_municipalEvaluation_provideMoreInfo_label,
			labelKey: 'request_form_municipalEvaluation_provideMoreInfo_label',
			value: answers.provideMoreInfo || '',
			required:
				answers.applicantStatus?.toString() ==
					REQUEST_CONFIGS_KEYS.applicanStatus.other || false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})
	//-----------------------------------------------------//
	const evaluationRoleList: SelectList[] = pageUtils.generateYearList(
		'2019',
		'2024',
		3
	).currentList

	const buildingValueToRoleList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_500kOrLess,
			value: REQUEST_CONFIGS_KEYS.buildingValues.less_than_500k,
			key: 'select_500kOrLess'
		},
		{
			label: pageAssets?.select_500kTo2mil,
			value: REQUEST_CONFIGS_KEYS.buildingValues.between_500k_and_2m,
			key: 'select_500kTo2mil'
		},
		{
			label: pageAssets?.select_2milTo5mil,
			value: REQUEST_CONFIGS_KEYS.buildingValues.between_2m_and_5m,
			key: 'select_2milTo5mil'
		},
		{
			label: pageAssets?.select_5milOrMore,
			value: REQUEST_CONFIGS_KEYS.buildingValues.above_5m,
			key: 'select_5milOrMore'
		}
	]

	const [evaluationRoleInput, setEvaluationRoleInput] = useState<RequestInputs>(
		{
			name: 'evaluationRole',
			label: pageAssets?.request_form_municipalEvaluation_evaluationRole_label,
			labelKey: 'request_form_municipalEvaluation_evaluationRole_label',
			value: answers.evaluationRole ?? evaluationRoleList[0].value,
			required: true,
			ref: useRef<HTMLInputElement>(null),
			description:
				pageAssets?.request_form_municipalEvaluation_evaluationRole_description_label,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	const [requestOriginInput, setRequestOriginInput] = useState<RequestInputs>({
		name: 'requestOrigin',
		label: pageAssets?.request_form_municipalEvaluation_requestOrigin_label,
		labelKey: 'request_form_municipalEvaluation_requestOrigin_label',
		value: answers.requestOrigin?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [roleModificationInput, setRoleModificationInput] =
		useState<RequestInputs>({
			name: 'roleModification',
			label:
				pageAssets?.request_form_municipalEvaluation_roleModification_label,
			labelKey: 'request_form_municipalEvaluation_roleModification_label',
			value: answers.roleModification || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [roleCorrectionInput, setRoleCorrectionInput] = useState<RequestInputs>(
		{
			name: 'roleCorrection',
			label: pageAssets?.request_form_municipalEvaluation_roleCorrection_label,
			labelKey: 'request_form_municipalEvaluation_roleCorrection_label',
			value: answers.roleCorrection || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	const [omissionInput, setOmissionInput] = useState<RequestInputs>({
		name: 'omission',
		label: pageAssets?.request_form_municipalEvaluation_omission_label,
		labelKey: 'request_form_municipalEvaluation_omission_label',
		description:
			pageAssets?.request_form_municipalEvaluation_omission_decription_label,
		value: answers.omission ?? '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})
	const [omissionOtherInput, setOmissionOtherInput] = useState<RequestInputs>({
		name: 'omissionOther',
		label: pageAssets?.request_form_municipalEvaluation_omission_label,
		labelKey: 'request_form_municipalEvaluation_omission_label',
		description:
			pageAssets?.request_form_municipalEvaluation_omission_decription_label,
		value: answers.omissionOther ?? '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [omissionList, setOmissionList] = useState<CheckboxList[]>()

	const [omissionStringInput, setOmissionStringInput] = useState<RequestInputs>(
		{
			name: 'omissionString',
			label: '',
			labelKey: '',
			value: answers.omissionString ?? '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	const [askerValueInput, setAskerValueInput] = useState<RequestInputs>({
		name: 'askerValue',
		label: pageAssets?.request_form_municipalEvaluation_askerValue_label,
		labelKey: 'request_form_municipalEvaluation_askerValue_label',
		value: answers.askerValue || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [otherInscriptionInput, setOtherInscriptionInput] =
		useState<RequestInputs>({
			name: 'otherInscription',
			label: pageAssets?.request_form_municipalEvaluation_provideMoreInfo_label,
			labelKey: 'request_form_municipalEvaluation_provideMoreInfo_label',
			value: answers.otherInscription || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [requestSupportInfoInput, setRequestSupportInfoInput] =
		useState<RequestInputs>({
			name: 'requestSupportInfo',
			label:
				pageAssets?.request_form_municipalEvaluation_requestSupportInfo_label,
			description:
				pageAssets?.request_form_municipalEvaluation_omission_decription_label,
			labelKey: 'request_form_municipalEvaluation_requestSupportInfo_label',
			value: answers.requestSupportInfo || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	//-----------------------------------------------------//

	const [buildingValueToRoleInput, setBuildingValueToRoleInput] =
		useState<RequestInputs>({
			name: 'buildingValueToRole',
			label:
				pageAssets?.request_form_municipalEvaluation_buildingValueToRole_label,
			labelKey: 'request_form_municipalEvaluation_buildingValueToRole_label',
			value: answers.buildingValueToRole ?? buildingValueToRoleList[0].value,
			required: true,
			ref: useRef<HTMLInputElement>(null),
			description:
				pageAssets?.request_form_municipalEvaluation_buildingValueToRole_label,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		})

	const [optionalFilesInput, setOptionalFilesInput] =
		useState<RequestInputFiles>({
			name: 'optionalFilesInput',
			label: pageAssets?.request_form_claimNotice_firstInput_label,
			labelKey: 'request_form_claimNotice_firstInput_label',
			description:
				pageAssets?.request_form_buildingTransactionRequest_optionalFilesInput_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'optionalFilesInput')?.files || []
			),
			required: false,
			numberMaxFiles: 10,
			category: requestCategories.other
		})
	//----------------------------------------------------------------
	//------------------- INPUTS SETTERS -----------------------------
	//----------------------------------------------------------------

	const onSelectApplicantStatusInput = (value: string) => {
		setApplicantStatusInput({ ...applicantStatusInput, value })

		if (value == REQUEST_CONFIGS_KEYS.applicanStatus.other) {
			setProvideMoreInfoInput({
				...provideMoreInfoInput,
				required: true
			})
		} else {
			setProvideMoreInfoInput({
				...provideMoreInfoInput,
				value: '',
				required: false
			})
		}

		if (value === '') {
			setShowTheOtherInput(true)
		} else {
			setShowTheOtherInput(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![applicantStatusInput.name, provideMoreInfoInput.name].includes(
						error
					)
			)
		)
	}

	const onSelectProvideMoreInfoInput = (value: string) => {
		setProvideMoreInfoInput({ ...provideMoreInfoInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, provideMoreInfoInput.name)
		)
	}
	//-----------------------------------------------------------------//
	const onSelectAppointmentTimeInput = (value: string) => {
		setEvaluationRoleInput({ ...evaluationRoleInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, evaluationRoleInput.name))
	}

	const onSelectRequestOriginInput = (value: string) => {
		setRequestOriginInput({ ...requestOriginInput, value })

		if (value != REQUEST_CONFIGS_KEYS.requestOrigin.changingRole) {
			setRoleModificationInput({ ...roleModificationInput, value: '' })
		}

		if (value != REQUEST_CONFIGS_KEYS.requestOrigin.correctionOfRole) {
			setRoleCorrectionInput({ ...roleCorrectionInput, value: '' })
		}

		onFixError(
			errors.filter((error) => ![requestOriginInput.name].includes(error))
		)
	}

	const onSelectRoleModificationInput = (value: string) => {
		setRoleModificationInput({ ...roleModificationInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, roleModificationInput.name)
		)
	}

	const onSelectRoleCorrectionInput = (value: string) => {
		setRoleCorrectionInput({ ...roleCorrectionInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, roleCorrectionInput.name))
	}

	const onSelectOmissionInput = (value: string, key: string) => {
		setOmissionInput({ ...omissionInput, value })

		pageUtils.onCheckValue(key, omissionList, setOmissionList, setOmissionInput)

		if (omissionList?.find((el) => el.checked) === undefined) {
			setOmissionInput({ ...omissionInput, value: '' })

			setAskerValueInput({ ...askerValueInput, required: false })
			setOtherInscriptionInput({ ...otherInscriptionInput, required: false })
		} else {
			value === '0'
				? setAskerValueInput({ ...askerValueInput, required: true })
				: setOtherInscriptionInput({ ...otherInscriptionInput, required: true })
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						omissionInput.name,
						askerValueInput.name,
						otherInscriptionInput.name,
						omissionOtherInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		if (omissionOtherInput.value?.trim() != '') {
			const values = omissionOtherInput.value?.split('\n')
			if (values[0].includes('x')) {
				setAskerValueInput({ ...askerValueInput, required: true })
			} else if (!values[0].includes('x')) {
				setAskerValueInput({ ...askerValueInput, required: false, value: '' })
			}

			if (values[1].includes('x')) {
				setOtherInscriptionInput({ ...otherInscriptionInput, required: true })
			} else if (!values[1].includes('x')) {
				setOtherInscriptionInput({
					...otherInscriptionInput,
					required: false,
					value: ''
				})
			}
		}
	}, [omissionOtherInput])

	const onSelectAskerValue = (value: string) => {
		setAskerValueInput({ ...askerValueInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, askerValueInput.name))
	}

	const onSelectOtherInscriptionInput = (value: string) => {
		setOtherInscriptionInput({ ...otherInscriptionInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, otherInscriptionInput.name)
		)
	}

	const onSelectRequestSupportInfoInput = (value: string) => {
		setRequestSupportInfoInput({ ...requestSupportInfoInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, requestSupportInfoInput.name)
		)
	}

	//-----------------------------------------------------------------//

	const onSelectBuildingValueToRoleInput = (value: string) => {
		setBuildingValueToRoleInput({ ...buildingValueToRoleInput, value })

		onFixError(
			errors.filter((error) => ![buildingValueToRoleInput.name].includes(error))
		)
	}

	const onSelectOptionalFilesInput = (files: Set<File>) => {
		setOptionalFilesInput({
			...optionalFilesInput,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, optionalFilesInput.name))
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const generateCleanStringFromAnswer = (value) => {
		let cleanString = ''
		let firstEl = true

		value?.map((el) => {
			if (el?.checked) {
				if (!firstEl) {
					cleanString += ','
				} else {
					firstEl = false
				}

				cleanString += el.value
			}
		})

		if (cleanString) {
			return cleanString
		}

		return ''
	}

	const diplayProvideMoreInfo = () => {
		return (
			<TextInput
				id={provideMoreInfoInput.name}
				label={provideMoreInfoInput.label}
				value={provideMoreInfoInput.value}
				onChange={onSelectProvideMoreInfoInput}
				hasError={errors.includes(provideMoreInfoInput.name)}
				required={
					applicantStatusInput.value ===
					REQUEST_CONFIGS_KEYS.applicanStatus.other
				}
				ref={provideMoreInfoInput.ref as RefObject<HTMLInputElement>}
				classWrapper={classes.inputContainer}
				description={provideMoreInfoInput.description}
			/>
		)
	}

	const displayRoleCorrection = () => {
		return (
			<TextInput
				id={roleCorrectionInput.name}
				label={roleCorrectionInput.label}
				value={roleCorrectionInput.value}
				onChange={onSelectRoleCorrectionInput}
				hasError={errors.includes(roleCorrectionInput.name)}
				required={
					requestOriginInput.value ===
					REQUEST_CONFIGS_KEYS.requestOrigin.correctionOfRole
				}
				ref={roleCorrectionInput.ref as RefObject<HTMLInputElement>}
				classWrapper={classes.inputContainer}
				description={roleCorrectionInput.description}
			/>
		)
	}

	const displayRoleModification = () => {
		return (
			<TextInput
				id={roleModificationInput.name}
				label={roleModificationInput.label}
				value={roleModificationInput.value}
				onChange={onSelectRoleModificationInput}
				hasError={errors.includes(roleModificationInput.name)}
				required={
					requestOriginInput.value ===
					REQUEST_CONFIGS_KEYS.requestOrigin.changingRole
				}
				ref={roleModificationInput.ref as RefObject<HTMLInputElement>}
				classWrapper={classes.inputContainer}
				description={roleModificationInput.description}
			/>
		)
	}

	const displayAskerValue = () => {
		return (
			<TextInput
				id={askerValueInput.name}
				label={askerValueInput.label}
				value={askerValueInput.value}
				onChange={onSelectAskerValue}
				hasError={errors.includes(askerValueInput.name)}
				required={askerValueInput.required}
				ref={askerValueInput.ref as RefObject<HTMLInputElement>}
				classWrapper={classes.inputContainer}
				description={askerValueInput.description}
			/>
		)
	}

	const displayOtherInscription = () => {
		return (
			<TextInput
				id={otherInscriptionInput.name}
				label={otherInscriptionInput.label}
				value={otherInscriptionInput.value}
				onChange={onSelectOtherInscriptionInput}
				hasError={errors.includes(otherInscriptionInput.name)}
				required={otherInscriptionInput.required}
				ref={otherInscriptionInput.ref as RefObject<HTMLInputElement>}
				classWrapper={classes.inputContainer}
				description={otherInscriptionInput.description}
			/>
		)
	}

	const radioListApplicantStatus: RadioList[] = [
		{
			label:
				pageAssets?.request_form_municipalEvaluation_applicantStatus_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.applicanStatus.owner,
			key: 'request_form_municipalEvaluation_applicantStatus_firstOption_label',
			updateDetails: true
		},
		{
			label:
				pageAssets?.request_form_municipalEvaluation_applicantStatus_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.applicanStatus.ownersAgent,
			key: 'request_form_municipalEvaluation_applicantStatus_secondOption_label',
			updateDetails: true
		},
		{
			label:
				pageAssets?.request_form_municipalEvaluation_applicantStatus_thirdOption_label,
			value: REQUEST_CONFIGS_KEYS.applicanStatus.other,
			key: 'request_form_municipalEvaluation_applicantStatus_thirdOption_label',
			extraText:
				pageAssets?.request_form_municipalEvaluation_applicantStatus_thirdOption_extraText_label,
			details: diplayProvideMoreInfo(),
			updateDetails: false
		}
	]

	const radioListOrigin: RadioList[] = [
		{
			label:
				pageAssets?.request_form_municipalEvaluation_origin_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.requestOrigin.newRole,
			key: 'request_form_municipalEvaluation_origin_firstOption_label',
			extraText:
				pageAssets?.request_form_municipalEvaluation_origin_firstOption_extraText_label,
			updateDetails: true
		},

		{
			label:
				pageAssets?.request_form_municipalEvaluation_origin_thirdOption_label,
			value: REQUEST_CONFIGS_KEYS.requestOrigin.changingRole,
			key: 'request_form_municipalEvaluation_origin_thirdOption_label',
			extraText:
				pageAssets?.request_form_municipalEvaluation_origin_thirdOption_extraText_label,
			details: displayRoleModification(),
			updateDetails: false
		},
		{
			label:
				pageAssets?.request_form_municipalEvaluation_origin_fourthOption_label,
			value: REQUEST_CONFIGS_KEYS.requestOrigin.correctionOfRole,
			extraText:
				pageAssets?.request_form_municipalEvaluation_origin_fourthOption_extraText_label,
			key: 'request_form_municipalEvaluation_origin_fourthOption_label',
			details: displayRoleCorrection(),
			updateDetails: true
		},
		{
			label:
				pageAssets?.request_form_municipalEvaluation_origin_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.requestOrigin.modificationNotMade,
			key: 'request_form_municipalEvaluation_origin_secondOption_label',
			extraText:
				pageAssets?.request_form_municipalEvaluation_origin_secondOption_extraText_label,

			updateDetails: false
		}
	]

	const contestedOmissions: pageUtils.CheckboxType[] = [
		{
			value: '0',
			extraText:
				pageAssets?.request_form_municipalEvaluation_omission_firstOption_extraText_label,
			key: 'request_form_municipalEvaluation_omission_firstOption_label',
			checked: false
		},
		{
			value: '1',
			extraText:
				pageAssets?.request_form_municipalEvaluation_omission_secondOption_extraText_label,
			key: 'request_form_municipalEvaluation_omission_secondOption_label',
			checked: false
		}
	]
	//----------------------------------------------------------------
	//------------------- USE EFFECTS --------------------------------
	//----------------------------------------------------------------

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, addressLotOrSerialInput))
	}, [addressLotOrSerialInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, applicantStatusInput))
	}, [applicantStatusInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, provideMoreInfoInput))
	}, [provideMoreInfoInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, evaluationRoleInput))
	}, [evaluationRoleInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, requestOriginInput))

		setBuildingValueToRoleInput({
			...buildingValueToRoleInput,
			description: getRoleDescription()
		})

		switch (requestOriginInput.value) {
			case REQUEST_CONFIGS_KEYS.requestOrigin.changingRole:
				setRoleModificationInput({ ...roleModificationInput, required: true })
				setRoleCorrectionInput({ ...roleCorrectionInput, required: false })

				break
			case REQUEST_CONFIGS_KEYS.requestOrigin.correctionOfRole:
				setRoleCorrectionInput({ ...roleCorrectionInput, required: true })
				setRoleModificationInput({ ...roleModificationInput, required: false })
				break
			default:
				setRoleModificationInput({ ...roleModificationInput, required: false })
				setRoleCorrectionInput({ ...roleCorrectionInput, required: false })
				break
		}
	}, [requestOriginInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, roleModificationInput))
	}, [roleModificationInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, roleCorrectionInput))
	}, [roleCorrectionInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, omissionInput))
		setOmissionOtherInput({
			...omissionOtherInput,
			value: omissionInput.value
		})
	}, [omissionInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, omissionOtherInput))
	}, [omissionOtherInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, askerValueInput))
	}, [askerValueInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, otherInscriptionInput))
	}, [otherInscriptionInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, requestSupportInfoInput))
	}, [requestSupportInfoInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingValueToRoleInput))
	}, [buildingValueToRoleInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, optionalFilesInput)
		)
	}, [optionalFilesInput])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: false,
			details_4: false,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	useEffect(() => {
		const omissionList = pageUtils.addLabelToList(
			contestedOmissions,
			pageAssets
		)

		// since a string is stored as the value of tranining and experience,
		// we need to convert it to a list of objects to match the format the checkbox inputs
		const omissionListAnswer = pageUtils.generateCheckboxListFromAnswer(
			contestedOmissions,
			answers?.omission,
			pageAssets
		)

		const omissionFormattedString =
			generateCleanStringFromAnswer(omissionListAnswer)

		setOmissionStringInput({
			...omissionStringInput,
			value: omissionFormattedString
		})

		setOmissionList(
			omissionListAnswer.length > 0 && !errors.includes(omissionInput.name)
				? omissionListAnswer
				: omissionList
		)
	}, [answers])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getRoleDescription = () => {
		switch (requestOriginInput.value) {
			case '':
			case REQUEST_CONFIGS_KEYS.requestOrigin.newRole:
				return pageAssets?.request_form_municipalEvaluation_newRole_label
			case REQUEST_CONFIGS_KEYS.requestOrigin.changingRole:
				return pageAssets?.request_form_municipalEvaluation_modifyRole_label

			case REQUEST_CONFIGS_KEYS.requestOrigin.correctionOfRole:
				return pageAssets?.request_form_municipalEvaluation_correctOfficeRole_label
		}
	}

	//----------------------------------------------------------------
	//---------------- CONSTANTS AND FUNCTIONS -----------------------
	//----------------------------------------------------------------
	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h5 className={pageUtils.classes.h5}>
								{
									pageAssets?.request_form_municipalEvaluation_firstSubstep_title
								}
							</h5>
						</div>

						{answers?.addressField && (
							<div>
								<strong>
									{
										pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label
									}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.addressField}
								</span>
							</div>
						)}

						{answers?.addressLotNumber && (
							<div>
								<strong>
									{
										pageAssets?.request_form_addressInfoSection_addressOption_secondOption_label
									}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.addressLotNumber}
								</span>
							</div>
						)}
						{answers?.addressRegistrationNumber && (
							<div>
								<strong>
									{
										pageAssets?.request_form_addressInfoSection_addressOption_thirdOption_label
									}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.addressRegistrationNumber}
								</span>
							</div>
						)}
						{answers?.addressAppartment && (
							<div>
								<strong>
									{formatStrapiText(pageAssets?.label_apartment_office)}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.addressAppartment}
								</span>
							</div>
						)}

						<div>
							<strong>{pageAssets[applicantStatusInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										radioListApplicantStatus.find(
											(applicantStatus) =>
												applicantStatus.value ==
												answers.applicantStatus?.toString()
										)?.key!
									]
								}
							</span>
						</div>
						{provideMoreInfoInput.value && (
							<div>
								<strong>{pageAssets[provideMoreInfoInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers.provideMoreInfo}
								</span>
							</div>
						)}
					</>
				)}

				{subSteps?.details_2 && (
					<div style={{ marginTop: '40px' }}>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_municipalEvaluation_secondSubstep_title}
						</h5>

						<div>
							<strong>
								{
									pageAssets?.request_form_municipalEvaluation_evaluationRole_label
								}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(evaluationRoleList, answers?.evaluationRole)}
							</span>
						</div>

						<div>
							<strong>{pageAssets[requestOriginInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										radioListOrigin.find(
											(requestOrigin) =>
												requestOrigin.value == answers.requestOrigin?.toString()
										)?.key!
									]
								}
								<br />
							</span>
						</div>

						{answers?.requestOrigin ==
							REQUEST_CONFIGS_KEYS.requestOrigin.changingRole &&
							answers?.roleModification && (
								<div>
									<strong>{roleModificationInput.label}</strong>
									<br />
									<span>{answers?.roleModification}</span>
								</div>
							)}

						{answers?.requestOrigin ==
							REQUEST_CONFIGS_KEYS.requestOrigin.correctionOfRole &&
							answers?.roleCorrection && (
								<div>
									<strong>{roleCorrectionInput.label}</strong>
									<br />
									<span>{answers?.roleCorrection}</span>
								</div>
							)}

						<div style={{ marginTop: '20px' }}>
							<strong>{pageAssets[omissionInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{pageUtils
									.getListfromString(answers.omission)
									?.map((answer, index) => {
										return (
											<>
												<strong key={`${answer}-${index}-omission`}>
													{
														pageAssets[
															omissionList?.find(
																(list) => list.value === answer
															)?.key!
														]
													}
												</strong>
												<div>
													<span className={pageUtils.classes.answer}>
														{answer === '0'
															? answers.askerValue
															: answers.otherInscription}
													</span>
												</div>
											</>
										)
									})}
							</span>
						</div>
						{answers?.requestSupportInfo && (
							<div className={classes.requestObjectWrapper}>
								<strong>{pageAssets[requestSupportInfoInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.requestSupportInfo}
								</span>
							</div>
						)}
					</div>
				)}
				{subSteps?.requiredDocument && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h5 className={pageUtils.classes.h5}>
								{pageAssets?.request_form_optional_documents}
							</h5>
						</div>

						<div>
							<strong>
								{
									pageAssets?.request_form_municipalEvaluation_buildingValueToRole_label
								}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									buildingValueToRoleList,
									answers?.buildingValueToRole
								)}
							</span>
						</div>
						{optionalFilesInput.files.size > 0 && (
							<RequestFiles
								subtitle={optionalFilesInput.label}
								name={optionalFilesInput.name}
								files={optionalFilesInput.files}
								hasError={errors.includes(optionalFilesInput.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectOptionalFilesInput}
								maxFiles={optionalFilesInput.numberMaxFiles}
								required={optionalFilesInput.required}
								isSummary
							>
								<></>
							</RequestFiles>
						)}
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<div
					style={{
						display:
							subSteps !== undefined &&
							subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
								? 'flex'
								: 'none'
					}}
					className={classes.collapseWrapper}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_municipalEvaluation_firstSubstep_title}
						</h4>

						<AddressInfoSection
							errors={errors}
							inputs={inputs}
							onFixError={onFixError}
							onSetInputs={onSetInputs}
							isConcernedInfosRequired={true}
							requiredFields={[
								'addressOption',
								'addressField',
								'addressLotNumber',
								'addressRegistrationNumber',
								'addressAppartment'
							]}
							subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1}
						/>

						<RadioInput
							name={applicantStatusInput.name}
							label={applicantStatusInput.label}
							value={applicantStatusInput.value}
							list={radioListApplicantStatus}
							direction={Direction.vertical}
							hasError={errors!.includes(applicantStatusInput.name)}
							onChange={onSelectApplicantStatusInput}
							ref={applicantStatusInput.ref as RefObject<HTMLInputElement>}
							required={applicantStatusInput.required}
							hasBackgroundWrapper={true}
						/>
					</section>
				</div>
			)}
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_municipalEvaluation_secondSubstep_title}
						</h4>

						<SelectInput
							id={evaluationRoleInput.name}
							label={evaluationRoleInput.label}
							value={evaluationRoleInput.value}
							list={evaluationRoleList}
							onChange={onSelectAppointmentTimeInput}
							hasError={errors.includes(evaluationRoleInput.name)}
							description={evaluationRoleInput.description}
							required={true}
							ref={evaluationRoleInput.ref as RefObject<HTMLSelectElement>}
						/>

						<RadioInput
							name={requestOriginInput.name}
							label={requestOriginInput.label}
							value={requestOriginInput.value}
							list={radioListOrigin}
							direction={Direction.vertical}
							hasError={errors!.includes(requestOriginInput.name)}
							onChange={onSelectRequestOriginInput}
							ref={requestOriginInput.ref as RefObject<HTMLInputElement>}
							required={requestOriginInput.required}
							hasBackgroundWrapper={true}
						/>

						<CheckboxInput
							name={omissionInput.name}
							label={omissionInput.label}
							value={`${omissionInput.value}`}
							list={omissionList}
							onChange={onSelectOmissionInput}
							required={omissionInput.required}
							hasError={errors.includes(omissionInput.name)}
							ref={omissionInput.ref as RefObject<HTMLInputElement>}
							setList={setOmissionList}
							setInput={setOmissionInput}
							errors={errors}
							onFixError={onFixError}
							hasBackgroundWrapper={true}
							UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
							subTextInputs={[displayAskerValue(), displayOtherInscription()]}
							shouldNotDivide
						/>

						<TextAreaInput
							id={requestSupportInfoInput.name}
							label={requestSupportInfoInput.label}
							hasDescriptionLabel={requestSupportInfoInput.description}
							displayDescriptionTop={true}
							descriptionAlignment="left"
							value={requestSupportInfoInput.value}
							onChange={onSelectRequestSupportInfoInput}
							hasError={errors.includes(requestSupportInfoInput.name)}
							required={requestSupportInfoInput.required}
							ref={
								requestSupportInfoInput.ref as RefObject<HTMLTextAreaElement>
							}
							maxCharacters={500}
						/>
					</section>
				</Collapse>
			)}
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_additional_info}
						</h4>

						<span className={pageUtils.classes.fileInfo}>
							{pageAssets?.page_requestInfo_inputFileDesc}
						</span>

						<SelectInput
							id={buildingValueToRoleInput.name}
							label={buildingValueToRoleInput.label}
							value={buildingValueToRoleInput.value}
							list={buildingValueToRoleList}
							onChange={onSelectBuildingValueToRoleInput}
							hasError={errors.includes(buildingValueToRoleInput.name)}
							description={buildingValueToRoleInput.description}
							required={buildingValueToRoleInput.required}
							ref={buildingValueToRoleInput.ref as RefObject<HTMLSelectElement>}
						/>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_optional_documents}
						</h5>

						<RequestFiles
							subtitle={optionalFilesInput.label}
							name={optionalFilesInput.name}
							files={optionalFilesInput.files}
							hasError={errors.includes(optionalFilesInput.name)}
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectOptionalFilesInput}
							subSteps={subSteps}
							maxFiles={10}
							required={optionalFilesInput.required}
							description={optionalFilesInput.description}
						></RequestFiles>
					</section>
				</Collapse>
			)}
		</>
	)
}

export default MunicipalEvaluationForm
