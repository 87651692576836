import axios, { AxiosError } from 'axios'
import {
	AxiosResponse,
	BackendPostResponseType,
	ICommentRepository
} from '@services/types'
import apiClient from '@services/api.client'
import { acquireAccessToken } from '@utils/authConfig'
import { ICommentDTO } from '@services/dtos/comments.dto'
import { IRequestDTO } from '@services/dtos/requests.dto'
import { ATTACHMENT_HEADER } from '@services/constants'
import { ACTIVITY_TYPE } from '@components/ui/historyCard/timelineBlock'

export class CommentRepository implements ICommentRepository {
	private resource: string
	private _partialsResources

	constructor() {
		;(this.resource = 'portal_comments'),
			(this._partialsResources = {
				attachments: 'attachments',
				isRead: 'is_read'
			})
	}

	postComment = async (CommentDTO: ICommentDTO): Promise<string> => {
		try {
			const response: AxiosResponse<BackendPostResponseType> =
				await apiClient.post('', CommentDTO, {
					params: {
						resource: this.resource
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data.resourceid
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while creating the comment: ' + error.message)
			}

			throw error
		}
	}

	getComments = async (requestId: IRequestDTO['incident_id']) => {
		try {
			const response: AxiosResponse<ICommentDTO[]> = await apiClient.get('', {
				params: {
					resource: this.resource,
					params: JSON.stringify({
						incident_id: requestId!,
						include_attachments_metadata: 'true'
					})
				},
				headers: {
					token: await acquireAccessToken()
				}
			})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while fetching the comments: ' + error.message)
			}

			throw error
		}
	}

	public setCommentAsRead = async (
		resourceId: string,
		activityType?: string
	) => {
		try {
			const resource =
				activityType === ACTIVITY_TYPE.EMAIL
					? 'emails'
					: activityType === ACTIVITY_TYPE.PHONE_CALL
					? 'phone_call'
					: activityType === ACTIVITY_TYPE.APPOINTMENT
					? 'appointment'
					: this.resource
			const response = await apiClient.patch('', null, {
				params: {
					resourceId,
					resource: resource,
					additionalResource: this._partialsResources.isRead
				},
				headers: {
					token: await acquireAccessToken()
				}
			})

			return response.status
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error(
					'Error while setting the comment as read: ' + error.message
				)
			}

			throw error
		}
	}

	public postAttachment = async (attachmentData: FormData): Promise<string> => {
		try {
			const response: AxiosResponse<BackendPostResponseType> =
				await apiClient.post('', attachmentData, {
					params: {
						resource: this._partialsResources.attachments
					},
					headers: {
						token: await acquireAccessToken(),
						...ATTACHMENT_HEADER
					}
				})

			return response.data.resourceid
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error(
					'Error while creating a attachment for the comment: ' + error.message
				)
			}

			throw error
		}
	}
}
