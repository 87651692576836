import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME
} from '@services/constants'
import RadioInput from './inputs/radio.input'
import TextInput from './inputs/text.inputs'
import Text from '@components/ui/text'
import RequestFiles from '@components/ui/requestFiles'
import { Colors } from '@utils/css-variables'
import { joinClasses, makeClasses } from '@utils/styles'
import BlueBulletsList, {
	BlueBulletsLabel
} from '@components/ui/blueBulletsList'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import {
	formatStrapiText,
	getFilesFromStore,
	getSeparetedFilesFromStore
} from '@utils/methods'
import RequiredIcon from '@components/ui/requiredIcon'
import { CustomDate } from '@services/models'
import Error from '@components/requestForms/inputs/error.input'
import RequestFilesWithMultipleButtons from '@components/ui/requestFilesWithMultipleButtons'
import DateFilterInput from './inputs/datefilter.input'

type Classes = {
	fileHelperSection: string
	fileContainer: string
}

const classes: Classes = makeClasses({
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	fileContainer: {
		'.blue-bullets-list': {
			ul: {
				padding: '0px 15px 0px 20px'
			}
		}
	}
})

const ClothDiapersForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	onCustomError
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const [duplicateFile, setDuplicateFile] = useState<boolean>(false)

	const [firstQuestion, setFirstQuestion] = useState<RequestInputs>({
		name: 'subventionChosen',
		label: pageAssets?.request_form_clothDiapers_subventionChosen_label,
		labelKey: 'request_form_clothDiapers_subventionChosen_label',
		value:
			answers.subventionChosen == 0 || !!answers.subventionChosen
				? `${answers.subventionChosen}`
				: '',
		valueKey: '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [secondQuestion, setSecondQuestion] = useState<RequestInputs>({
		name: 'childFirstName',
		label: pageAssets?.request_form_clothDiapers_childFirstName_label,
		labelKey: 'request_form_clothDiapers_childFirstName_label',
		value: answers.childFirstName || '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [thirdQuestion, setThirdQuestion] = useState<RequestInputs>({
		name: 'childLastName',
		label: pageAssets?.request_form_clothDiapers_childLastName_label,
		labelKey: 'request_form_clothDiapers_childLastName_label',
		value: answers.childLastName || '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [fourthQuestion, setFourthQuestion] = useState<RequestInputs>({
		name: 'childBirthDate',
		label: pageAssets?.request_form_clothDiapers_childBirthDate_label,
		labelKey: 'request_form_clothDiapers_childBirthDate_label',
		value: answers.childBirthDate || '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [fifthQuestion, setFifthQuestion] = useState<RequestInputs>({
		name: 'adultResidentNumber',
		label: pageAssets?.request_form_clothDiapers_adultResidentNumber_label,
		labelKey: 'request_form_clothDiapers_adultResidentNumber_label',
		value: answers.adultResidentNumber?.toString() || '',
		// Check the configs for answers already entered for *subventionChosen*, to update the input required option
		required:
			REQUEST_CONFIGS_KEYS.clothDiapers.secondOption ==
			String(answers.subventionChosen),
		ref: useRef<HTMLInputElement>(null)
	})

	const [sixthQuestion, setSixthQuestion] = useState<RequestInputs>({
		name: 'childrenResidentNumber',
		label: pageAssets?.request_form_clothDiapers_childrenResidentNumber_label,
		labelKey: 'request_form_clothDiapers_childrenResidentNumber_label',
		value: answers.childrenResidentNumber?.toString() || '',
		// Check the configs for answers already entered for *subventionChosen*, to update the input required option
		required:
			REQUEST_CONFIGS_KEYS.clothDiapers.secondOption ==
			String(answers.subventionChosen),
		ref: useRef<HTMLInputElement>(null)
	})

	const [seventhQuestion, setSeventhQuestion] = useState<RequestInputFiles>({
		name: 'seventhInput',
		label: pageAssets?.request_form_clothDiapers_seventhQuestion_label,
		labelKey: 'request_form_clothDiapers_seventhQuestion_label',
		description:
			pageAssets?.request_form_clothDiapers_seventhQuestion_description_label,
		files: new Set<File>(),
		required: true,
		numberFilesRequired: 2,
		separateFiles:
			filesInputs?.find((el) => el.name == 'seventhInput')?.separateFiles || {}
	})

	const [eighthQuestion, setEighthQuestion] = useState<RequestInputFiles>({
		name: 'eighthInput',
		label: pageAssets?.request_form_clothDiapers_eighthQuestion_label,
		labelKey: 'request_form_clothDiapers_eighthQuestion_label',
		description:
			pageAssets?.request_form_clothDiapers_eighthQuestion_description_label,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eighthInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1
	})

	const [ninthQuestion, setNinthQuestion] = useState<RequestInputFiles>({
		name: 'ninthInput',
		label: pageAssets?.request_form_clothDiapers_ninthQuestion_label,
		labelKey: 'request_form_clothDiapers_ninthQuestion_label',
		description:
			pageAssets?.request_form_clothDiapers_ninthQuestion_description_label,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'ninthInput')?.files || []
		),
		// Check the configs for answers already entered for *subventionChosen*, to update the input required option
		required:
			REQUEST_CONFIGS_KEYS.clothDiapers.firstOption ==
			String(answers.subventionChosen),
		numberFilesRequired: 1,
		numberMaxFiles: 10
	})

	const [tenthQuestion, setTenthQuestion] = useState<RequestInputFiles>({
		name: 'tenthInput',
		label: pageAssets?.request_form_clothDiapers_tenthQuestion_label,
		labelKey: 'request_form_clothDiapers_tenthQuestion_label',
		description:
			pageAssets?.request_form_clothDiapers_tenthQuestion_description_label,
		files: new Set<File>(),
		// Check the configs for answers already entered for *subventionChosen* & *adultResidentNumber*, to update the input required option
		required:
			REQUEST_CONFIGS_KEYS.clothDiapers.secondOption ==
				String(answers.subventionChosen) &&
			Number(answers.adultResidentNumber) > 0,
		separateFiles:
			filesInputs?.find((el) => el.name == 'tenthInput')?.separateFiles || {},
		// Check the configs for answers already entered for *adultResidentNumber*, to update the input numberFilesRequired option
		numberFilesRequired: Number(answers.adultResidentNumber) ?? 0
	})

	const firstQuestionList: RadioList[] = [
		{
			label: pageAssets?.select_clothDiapers_firstQuestion_firstOption,
			value: REQUEST_CONFIGS_KEYS.clothDiapers.firstOption,
			key: 'select_clothDiapers_firstQuestion_firstOption',
			extraText: pageAssets?.select_clothDiapers_firstQuestion_firstOption_extra
		},
		{
			label: pageAssets?.select_clothDiapers_firstQuestion_secondOption,
			value: REQUEST_CONFIGS_KEYS.clothDiapers.secondOption,
			key: 'select_clothDiapers_firstQuestion_secondOption',
			extraText:
				pageAssets?.select_clothDiapers_firstQuestion_secondOption_extra
		}
	]
	// const seventhQuestionListDescription: BlueBulletsLabel[] = [
	// 	{
	// 		name: pageAssets?.request_form_clothDiapers_seventhQuestion_list_firstDesc_label
	// 	},
	// 	{
	// 		name: pageAssets?.request_form_clothDiapers_seventhQuestion_list_secondDesc_label
	// 	}
	// ]

	// const ninthQuestionListDescription: BlueBulletsLabel[] = [
	// 	{
	// 		name: pageAssets?.request_form_clothDiapers_ninthQuestion_list_firstDesc_label
	// 	},
	// 	{
	// 		name: pageAssets?.request_form_clothDiapers_ninthQuestion_list_secondDesc_label
	// 	},
	// 	{
	// 		name: pageAssets?.request_form_clothDiapers_ninthQuestion_list_thirdDesc_label
	// 	},
	// 	{
	// 		name: pageAssets?.request_form_clothDiapers_ninthQuestion_list_fourDesc_label
	// 	}
	// ]

	const onSelectFirstQuestion = (value: string, valueKey: string) => {
		let tmpErrors: string[] = UpdateRequestInputsErrorsArray(
			errors,
			firstQuestion.name
		)

		setFirstQuestion({ ...firstQuestion, value, valueKey })

		if (value === REQUEST_CONFIGS_KEYS.clothDiapers.firstOption) {
			tmpErrors = UpdateRequestInputsErrorsArray(tmpErrors, sixthQuestion.name)
			tmpErrors = UpdateRequestInputsErrorsArray(tmpErrors, fifthQuestion.name)
			tmpErrors = UpdateRequestInputsErrorsArray(tmpErrors, tenthQuestion.name)

			setFifthQuestion({ ...fifthQuestion, value: '', required: false })
			setSixthQuestion({ ...sixthQuestion, value: '', required: false })
			setNinthQuestion({ ...ninthQuestion, required: true })

			setTenthQuestion({ ...tenthQuestion, required: false })

			onFixError(tmpErrors)

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(tmpErrors, ninthQuestion.name))

		setFifthQuestion({ ...fifthQuestion, required: true })
		setSixthQuestion({ ...sixthQuestion, required: true })
		setTenthQuestion({ ...tenthQuestion, required: true })

		setNinthQuestion({ ...ninthQuestion, required: false })
	}

	const onSelectSecondQuestion = (value: string) => {
		setSecondQuestion({ ...secondQuestion, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, secondQuestion.name))
	}

	const onSelectThirdQuestion = (value: string) => {
		setThirdQuestion({ ...thirdQuestion, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, thirdQuestion.name))
	}

	const onSelectFourthQuestion = (value: string) => {
		setFourthQuestion({ ...fourthQuestion, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, fourthQuestion.name))
	}

	const onSelectFifthQuestion = (value: string) => {
		if (value !== '' && Number(value) < 0) {
			return
		}

		setFifthQuestion({ ...fifthQuestion, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, fifthQuestion.name))

		if (Number(value) > 0) {
			setTenthQuestion({
				...tenthQuestion,
				required: Number(value) > 0,
				numberFilesRequired: Number(value)
			})
		} else {
			const { numberFilesRequired, ...temp } = tenthQuestion
			setTenthQuestion({ ...temp, required: Number(value) > 0 })
		}
	}

	const onSelectSixthQuestion = (value: string) => {
		if (value !== '' && Number(value) < 1) {
			return
		}
		setSixthQuestion({ ...sixthQuestion, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, sixthQuestion.name))
	}

	const onSelectFileEighthQuestion = (files: Set<File>) => {
		setEighthQuestion({
			...eighthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, eighthQuestion.name))
	}

	const onSelectFileNinthQuestion = (files: Set<File>) => {
		setNinthQuestion({
			...ninthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, ninthQuestion.name))
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const shouldTenthQuestionDisplay = (): boolean =>
		firstQuestion.value === REQUEST_CONFIGS_KEYS.clothDiapers.secondOption &&
		fifthQuestion.value !== ''

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, fourthQuestion))
	}, [fourthQuestion])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, fifthQuestion))
	}, [fifthQuestion])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, sixthQuestion))
	}, [sixthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhQuestion))
	}, [seventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eighthQuestion))
	}, [eighthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, ninthQuestion))
	}, [ninthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, tenthQuestion))
	}, [tenthQuestion])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// seventhInput input
		// separateFiles
		if (
			!!answers.seventhInput &&
			Object.keys(seventhQuestion.separateFiles!).length == 0
		) {
			getSeparetedFilesFromStore('seventhInput', answers).then((result) => {
				setSeventhQuestion({
					...seventhQuestion,
					separateFiles: result
				})
			})
		}
		// eighthInput input
		if (!!answers.eighthInput && eighthQuestion.files.size == 0) {
			getFilesFromStore('eighthInput', answers).then((result: Set<File>) => {
				setEighthQuestion({
					...eighthQuestion,
					files: result
				})
			})
		}
		// ninthInput input
		if (!!answers.ninthInput && ninthQuestion.files.size == 0) {
			getFilesFromStore('ninthInput', answers).then((result: Set<File>) => {
				setNinthQuestion({
					...ninthQuestion,
					files: result
				})
			})
		}
		// tenthInput input
		// separateFiles
		if (
			!!answers.tenthInput &&
			Object.keys(tenthQuestion.separateFiles!).length == 0
		) {
			getSeparetedFilesFromStore('tenthInput', answers).then((result) => {
				setTenthQuestion({
					...tenthQuestion,
					separateFiles: result
				})
			})
		}
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_grant_details}
							</h4>
						</div>
						<div>
							<strong>{pageAssets[firstQuestion.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{pageAssets[firstQuestionList[answers.subventionChosen!]?.key]}
							</span>
						</div>

						<div>
							<strong>{pageAssets[secondQuestion.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.childFirstName}
							</span>
						</div>

						<div>
							<strong>{pageAssets[thirdQuestion.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.childLastName}
							</span>
						</div>

						<div>
							<strong>{pageAssets[fourthQuestion.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{pageUtils.getLocaleDate(answers.childBirthDate!)}
							</span>
						</div>

						{answers.subventionChosen ==
							parseInt(REQUEST_CONFIGS_KEYS.clothDiapers.secondOption) && (
							<>
								<div className={pageUtils.classes.answer}>
									<strong>{pageAssets[fifthQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.adultResidentNumber}
									</span>
								</div>

								<div className={pageUtils.classes.answer}>
									<strong>{pageAssets[sixthQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.childrenResidentNumber}
									</span>
								</div>
							</>
						)}
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_required_documents}
							</h4>
						</div>

						<RequestFilesWithMultipleButtons
							input={seventhQuestion}
							hasError={errors.includes(seventhQuestion.name)}
							setInput={setSeventhQuestion}
							numberOfFiles={2}
							onSetHasError={onSetErrorFromFileComponent}
							onFixError={onFixError}
							UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
							errors={errors}
							steps={steps!}
							subSteps={subSteps!}
							formPosition={formPosition!}
							buttonLabels={pageAssets?.request_from_residence_proof_number}
							isSummary
						>
							<></>
						</RequestFilesWithMultipleButtons>

						<RequestFiles
							subtitle={eighthQuestion.label}
							name={eighthQuestion.name}
							required
							files={eighthQuestion.files}
							hasError={errors.includes(eighthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileEighthQuestion}
							maxFiles={eighthQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						{answers.subventionChosen ==
							parseInt(REQUEST_CONFIGS_KEYS.clothDiapers.firstOption) && (
							<RequestFiles
								subtitle={ninthQuestion.label}
								name={ninthQuestion.name}
								required={ninthQuestion.required}
								files={ninthQuestion.files}
								hasError={errors.includes(ninthQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileNinthQuestion}
								isSummary
							>
								<></>
							</RequestFiles>
						)}
						{answers.subventionChosen ==
							parseInt(REQUEST_CONFIGS_KEYS.clothDiapers.secondOption) && (
							<>
								<RequestFilesWithMultipleButtons
									input={tenthQuestion}
									hasError={errors.includes(tenthQuestion.name)}
									setInput={setTenthQuestion}
									numberOfFiles={parseInt(fifthQuestion.value)}
									onSetHasError={onSetErrorFromFileComponent}
									onFixError={onFixError}
									UpdateRequestInputsErrorsArray={
										UpdateRequestInputsErrorsArray
									}
									errors={errors}
									formPosition={formPosition!}
									steps={steps!}
									subSteps={subSteps!}
									isSummary
									buttonLabels={pageAssets?.label_copy_number}
								>
									<></>
								</RequestFilesWithMultipleButtons>
							</>
						)}
					</>
				)}
			</section>
		)
	}

	return (
		<>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
			>
				<section>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_grant_details}
					</h4>

					<div className={pageUtils.classes.radioLabel}>
						<RadioInput
							name={firstQuestion.name}
							label={firstQuestion.label}
							value={firstQuestion.value}
							list={firstQuestionList}
							onChange={onSelectFirstQuestion}
							hasError={errors.includes(firstQuestion.name)}
							required
							ref={firstQuestion.ref as RefObject<HTMLInputElement>}
						/>
					</div>

					<h5 className={pageUtils.classes.h5}>
						{pageAssets?.requestForm_clothDiapper_identification_of_the_child}
					</h5>

					<div className={pageUtils.classes.fieldsContainer}>
						<TextInput
							id={secondQuestion.name}
							label={secondQuestion.label}
							value={secondQuestion.value}
							onChange={onSelectSecondQuestion}
							hasError={errors.includes(secondQuestion.name)}
							required
							ref={secondQuestion.ref as RefObject<HTMLInputElement>}
							classWrapper={pageUtils?.classes.textFieldContainer}
						/>
						<TextInput
							id={thirdQuestion.name}
							label={thirdQuestion.label}
							value={thirdQuestion.value}
							onChange={onSelectThirdQuestion}
							hasError={errors.includes(thirdQuestion.name)}
							required
							ref={thirdQuestion.ref as RefObject<HTMLInputElement>}
							classWrapper={pageUtils?.classes.textFieldContainer}
						/>
					</div>

					<div className={pageUtils.classes.fieldsContainer}>
						<DateFilterInput
							id={fourthQuestion.name}
							label={fourthQuestion.label}
							value={fourthQuestion.value}
							onChange={onSelectFourthQuestion}
							hasError={errors.includes(fourthQuestion.name)}
							required
							classWrapper={pageUtils?.classes.textFieldContainer}
							maxDate={new Date(CustomDate.currentDate())}
							dateFormat="dd/MM/yyyy"
						/>
						{/* 
										<TextInput
											type="date"
											max={CustomDate.currentDate()}
											id={fourthQuestion.name}
											label={fourthQuestion.label}
											value={fourthQuestion.value}
											onChange={onSelectFourthQuestion}
											hasError={errors.includes(fourthQuestion.name)}
											required
											ref={fourthQuestion.ref as RefObject<HTMLInputElement>}
											classWrapper={pageUtils?.classes.textFieldContainer}
							/>*/}
					</div>

					{firstQuestion.value ===
						REQUEST_CONFIGS_KEYS.clothDiapers.secondOption && (
						<>
							<h5 className={pageUtils.classes.h5}>
								<Text
									content={
										pageAssets?.request_form_clothDiapers_smallTitle_label
									}
								/>
							</h5>

							<div className={pageUtils.classes.fieldsContainer}>
								<TextInput
									type="number"
									id={fifthQuestion.name}
									label={fifthQuestion.label}
									value={fifthQuestion.value}
									onChange={onSelectFifthQuestion}
									required={fifthQuestion.required}
									hasError={errors.includes(fifthQuestion.name)}
									ref={fifthQuestion.ref as RefObject<HTMLInputElement>}
									classWrapper={pageUtils?.classes.textFieldContainer}
									onKeyPress={(event) => {
										const charCode = event.which ? event.which : event.keyCode
										const char = String.fromCharCode(charCode)

										if (!/^\d$/.test(char)) {
											event.preventDefault()
										}
									}}
								/>
							</div>

							<div className={pageUtils.classes.fieldsContainer}>
								<TextInput
									type="number"
									id={sixthQuestion.name}
									label={sixthQuestion.label}
									value={sixthQuestion.value}
									onChange={onSelectSixthQuestion}
									required={sixthQuestion.required}
									hasError={errors.includes(sixthQuestion.name)}
									ref={sixthQuestion.ref as RefObject<HTMLInputElement>}
									classWrapper={pageUtils?.classes.textFieldContainer}
									min={'1'}
									onKeyPress={(event) => {
										const charCode = event.which ? event.which : event.keyCode
										const char = String.fromCharCode(charCode)

										if (!/^\d$/.test(char)) {
											event.preventDefault()
										}
									}}
								/>
							</div>
						</>
					)}
				</section>
			</Collapse>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				}
			>
				<section className={pageUtils.classes.sectionNoPadding}>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_required_documents}
					</h4>

					<span className={pageUtils.classes.fileInfo}>
						{pageAssets?.page_requestInfo_inputFileDesc}
					</span>

					<RequestFilesWithMultipleButtons
						input={seventhQuestion}
						hasError={errors.includes(seventhQuestion.name)}
						setInput={setSeventhQuestion}
						numberOfFiles={2}
						onSetHasError={onSetErrorFromFileComponent}
						onFixError={onFixError}
						UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
						errors={errors}
						steps={steps!}
						subSteps={subSteps!}
						formPosition={formPosition!}
						buttonLabels={pageAssets?.request_from_residence_proof_number}
						description={seventhQuestion.description}
					></RequestFilesWithMultipleButtons>

					<RequestFiles
						subtitle={eighthQuestion.label}
						name={eighthQuestion.name}
						required
						files={eighthQuestion.files}
						hasError={errors.includes(eighthQuestion.name)}
						removeSectionMarginTop
						subSteps={subSteps}
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileEighthQuestion}
						maxFiles={eighthQuestion.numberFilesRequired}
						description={eighthQuestion.description}
					></RequestFiles>

					{firstQuestion.value ===
						REQUEST_CONFIGS_KEYS.clothDiapers.firstOption && (
						<RequestFiles
							subtitle={ninthQuestion.label}
							name={ninthQuestion.name}
							required={ninthQuestion.required}
							files={ninthQuestion.files}
							hasError={errors.includes(ninthQuestion.name)}
							removeSectionMarginTop
							subSteps={subSteps}
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileNinthQuestion}
							description={ninthQuestion.description}
							maxFiles={ninthQuestion.numberMaxFiles}
						>
							{/* <div
								className={joinClasses([
									classes.fileHelperSection,
									classes.fileContainer
								])}
							>
								<Text content={ninthQuestion.description} />
								<BlueBulletsList labels={ninthQuestionListDescription} text />
							</div> */}
						</RequestFiles>
					)}
					{shouldTenthQuestionDisplay() && (
						<>
							<RequestFilesWithMultipleButtons
								input={tenthQuestion}
								hasError={errors.includes(tenthQuestion.name)}
								setInput={setTenthQuestion}
								numberOfFiles={parseInt(fifthQuestion.value)}
								onSetHasError={onSetErrorFromFileComponent}
								onFixError={onFixError}
								UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
								errors={errors}
								steps={steps!}
								subSteps={subSteps!}
								formPosition={formPosition!}
								buttonLabels={pageAssets?.label_copy_number}
								description={tenthQuestion.description}
							></RequestFilesWithMultipleButtons>
						</>
					)}
				</section>
			</Collapse>
		</>
	)
}

export default ClothDiapersForm
