import React, {
	FC,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { RadioList, RequestInputs, TNRequestNames } from '@utils/request'
import { Direction, REQUEST_CONFIGS_KEYS } from '@services/constants'
import RadioInput from '@components/requestForms/inputs/radio.input'
import requestForm from '@components/requestForms/request.form'
import MapForm from '../map/MapForm'
import TextInput from '@components/requestForms/inputs/text.inputs'
import { useAppSelector } from '@services/store'
// import MaskedTextInput from './inputs/textWithValidation.inputs'
import { AddressInfo } from '../map/mapForm.interfaces'
import {
	AddressFieldNames,
	AddressInfoSectionProps,
	classes
} from './__index.utils'
import {
	getAddressByCoordinates,
	getDefaultCadastralNumber
} from '../addressForm/__index.utils'
import Notification from '../notification'
import { Collapse } from 'react-collapse'
import { closeIcon } from '@images/icons'
import { Icon } from '../Icon'
import { joinClasses } from '@utils/styles'
import TextAreaInput from '@components/requestForms/inputs/textarea.inputs'

const AddressVoiePublicSection: FC<AddressInfoSectionProps> = ({
	form,
	label,
	errors,
	inputs,
	isConcernedInfosRequired,
	subStep,
	requiredFields,
	addressOptions,
	displayAddressDetails = true,
	showButtonUserLocation = true,
	onFixError,
	onSetInputs,
	onGetCadastralAndMatriculeNumber
}) => {
	const { authUser } = useContext<AppContextProps>(AppStateContext)

	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const radioListAddressOption: RadioList[] = addressOptions ?? [
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.cadastralNumber,
			key: 'request_form_addressInfoSection_addressOption_secondOption_label'
		},
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.address,
			key: 'request_form_addressInfoSection_addressOption_firstOption_label'
		}
	]

	const [nbrOfAddress, setNbrOfAddress] = useState<RequestInputs>({
		name: 'nbrOfAddress',
		label: pageAssets?.request_form_addressInfoSection_addressOption_label,
		labelKey: 'request_form_addressInfoSection_addressOption_label',
		value: answers?.nbrOfAddress?.toString() ?? '1',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: subStep
	})

	const [TMPAddressLotNumber, setTMPAddressLotNumber] = useState<string>('')

	const [addressOptionInput, setaddressOptionInput] = useState<RequestInputs>({
		name: AddressFieldNames.addressOption,
		label: pageAssets?.request_form_addressInfoSection_addressOption_label,
		labelKey: 'request_form_addressInfoSection_addressOption_label',
		value:
			answers?.addressOption?.toString() ?? radioListAddressOption[0].value,
		required: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: subStep
	})

	const [addressFieldInput, setAddressFieldInput] = useState<RequestInputs>({
		name: AddressFieldNames.addressField,
		label:
			pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
		labelKey: 'request_form_addressInfoSection_addressOption_firstOption_label',
		value: answers?.addressField || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: subStep
	})

	const [addressLotNumberInput, setAddressLotNumberInput] =
		useState<RequestInputs>({
			name: AddressFieldNames.addressLotNumber,
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_secondOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_secondOption_label',
			value: answers?.addressLotNumber?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [addressVoiePublicInput, setAddressVoiePublicInput] =
		useState<RequestInputs>({
			name: 'addressVoiePublic',
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_firstOption_label',
			value: answers?.addressVoiePublic || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [addressCivicNumberInput, setAddressCivicNumberInput] =
		useState<RequestInputs>({
			name: 'addressCivicNumber',
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_firstOption_label',
			value: answers?.addressCivicNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [addressVoiePublic2Input, setAddressVoiePublic2Input] =
		useState<RequestInputs>({
			name: 'addressVoiePublic2',
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_firstOption_label',
			value: answers?.addressVoiePublic2 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [addressCivicNumber2Input, setAddressCivicNumber2Input] =
		useState<RequestInputs>({
			name: 'addressCivicNumber2',
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_firstOption_label',
			value: answers?.addressCivicNumber2 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [addressVoiePublic3Input, setAddressVoiePublic3Input] =
		useState<RequestInputs>({
			name: 'addressVoiePublic3',
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_firstOption_label',
			value: answers?.addressVoiePublic3 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [addressCivicNumber3Input, setAddressCivicNumber3Input] =
		useState<RequestInputs>({
			name: 'addressCivicNumber3',
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_firstOption_label',
			value: answers?.addressCivicNumber3 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [addressAdditionalInfosInput, setAddressAdditionalInfosInput] =
		useState<RequestInputs>({
			name: 'addressAdditionalInfos',
			label:
				pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label,
			labelKey:
				'request_form_addressInfoSection_addressOption_firstOption_label',
			value: answers?.addressAdditionalInfos || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [displayInfo, setDisplayInfo] = useState<boolean>(false)
	const [displayWarning, setDisplayWarning] = useState<boolean>(false)
	const [displayAddressInfo, setDisplayAddressInfo] = useState<boolean>(false)
	//------------------------------------------------------------
	//------------------------------------------------------------
	//------------------------------------------------------------
	const onsetaddressOptionInput = (value: string) => {
		//radio on set with conditional data being saved in the addressInput
		setaddressOptionInput({
			...addressOptionInput,
			value
		})

		// console.log('IN onsetaddressOptionInput')
		clearAllFields()

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, addressOptionInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(addressOptionInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, addressOptionInput))
	}, [addressOptionInput])

	//------------------------------------------------------------
	const onSetAddressFieldInput = (value: string) => {
		if (value === '') {
			if (
				addressOptionInput.value ==
				REQUEST_CONFIGS_KEYS.researchType.cadastralNumber
			) {
				setAddressLotNumberInput({
					...addressLotNumberInput,
					value: TMPAddressLotNumber
				})
			} else {
				setAddressLotNumberInput({ ...addressLotNumberInput, value })
			}

			setDisplayInfo(false)
			return
		}

		switch (addressOptionInput.value) {
			case REQUEST_CONFIGS_KEYS.researchType.cadastralNumber:
				//set addressField
				setAddressLotNumberInput({ ...addressLotNumberInput, value })
				break

			default:
				setAddressFieldInput({ ...addressFieldInput, value })
				break
		}
		onGetCadastralAndMatriculeNumber && onGetCadastralAndMatriculeNumber(value)

		setDisplayInfo(true)
		onFixError &&
			errors &&
			onFixError(
				errors.filter(
					(error) =>
						![
							addressLotNumberInput.name,
							// addressRegistrationNumberInput.name,
							addressFieldInput.name
						].includes(error)
				)
			)
	}

	// Reusable function to set input and handle errors
	const handleInputChange = (setInput, input, value, inputName) => {
		setInput({
			...input,
			value
		})

		onFixError && onFixError(UpdateRequestInputsErrorsArray(errors!, inputName))
	}

	// Reusable hook to update inputs
	const useUpdateInputs = (input) => {
		useEffect(() => {
			onSetInputs && onSetInputs(UpdateRequestInputsArray(inputs!, input))
		}, [input])
	}

	const onSelectAddressFieldInput = (value: string) => {
		setAddressFieldInput({ ...addressFieldInput, value })
		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, addressFieldInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(addressFieldInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, addressFieldInput))
	}, [addressFieldInput])

	//------------------------------------------------------------
	const onSetAddressLotNumberInput = (value: string) => {
		setAddressLotNumberInput({ ...addressLotNumberInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, addressLotNumberInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(addressLotNumberInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, addressLotNumberInput))
	}, [addressLotNumberInput])

	//------------------------------------------------------------

	const onAddNbrOfAddress = () => {
		setNbrOfAddress({
			...nbrOfAddress,
			value: (parseInt(nbrOfAddress.value) + 1).toString()
		})
	}

	const onRemoveNbrOfAddress = (nbr: number) => {
		if (parseInt(nbrOfAddress.value) == nbr) {
			setNbrOfAddress({
				...nbrOfAddress,
				value: (parseInt(nbrOfAddress.value) - 1).toString()
			})

			if (nbr == 2) {
				setAddressVoiePublic2Input({ ...addressVoiePublic2Input, value: '' })
				setAddressCivicNumber2Input({ ...addressCivicNumber2Input, value: '' })
			}

			if (nbr == 3) {
				setAddressVoiePublic3Input({ ...addressVoiePublic3Input, value: '' })
				setAddressCivicNumber3Input({ ...addressCivicNumber3Input, value: '' })
			}

			if (nbr == 4) {
				setAddressAdditionalInfosInput({
					...addressAdditionalInfosInput,
					value: ''
				})
			}
		}

		onFixError &&
			onFixError(
				errors! &&
					errors.filter(
						(error) =>
							![
								nbrOfAddress.name,
								addressFieldInput.name,
								addressLotNumberInput.name,
								addressVoiePublicInput.name,
								addressCivicNumberInput.name,
								addressVoiePublic2Input.name,
								addressCivicNumber2Input.name,
								addressVoiePublic3Input.name,
								addressCivicNumber3Input.name,
								addressAdditionalInfosInput.name
							].includes(error)
					)
			)
	}

	useUpdateInputs(nbrOfAddress)

	// ------------------------------------------------------------

	const onSetAddressVoiePublicInput = (value: string) => {
		handleInputChange(
			setAddressVoiePublicInput,
			addressVoiePublicInput,
			value,
			addressVoiePublicInput.name
		)
	}

	useUpdateInputs(addressVoiePublicInput)

	// ------------------------------------------------------------

	const onSetAddressCivicNumberInput = (value: string) => {
		handleInputChange(
			setAddressCivicNumberInput,
			addressCivicNumberInput,
			value,
			addressCivicNumberInput.name
		)
	}

	useUpdateInputs(addressCivicNumberInput)

	// ------------------------------------------------------------

	const onSetAddressVoiePublic2Input = (value: string) => {
		handleInputChange(
			setAddressVoiePublic2Input,
			addressVoiePublic2Input,
			value,
			addressVoiePublic2Input.name
		)
	}

	useUpdateInputs(addressVoiePublic2Input)

	// ------------------------------------------------------------

	const onSetAddressCivicNumber2Input = (value: string) => {
		handleInputChange(
			setAddressCivicNumber2Input,
			addressCivicNumber2Input,
			value,
			addressCivicNumber2Input.name
		)
	}

	useUpdateInputs(addressCivicNumber2Input)

	// ------------------------------------------------------------

	const onSetAddressVoiePublic3Input = (value: string) => {
		handleInputChange(
			setAddressVoiePublic3Input,
			addressVoiePublic3Input,
			value,
			addressVoiePublic3Input.name
		)
	}

	useUpdateInputs(addressVoiePublic3Input)

	// ------------------------------------------------------------

	const onSetAddressCivicNumber3Input = (value: string) => {
		handleInputChange(
			setAddressCivicNumber3Input,
			addressCivicNumber3Input,
			value,
			addressCivicNumber3Input.name
		)
	}

	useUpdateInputs(addressCivicNumber3Input)

	// ------------------------------------------------------------

	const onSetAddressAdditionalInfosInput = (value: string) => {
		handleInputChange(
			setAddressAdditionalInfosInput,
			addressAdditionalInfosInput,
			value,
			addressAdditionalInfosInput.name
		)
	}

	useUpdateInputs(addressAdditionalInfosInput)

	// ------------------------------------------------------------

	useEffect(() => {
		if (isConcernedInfosRequired == true) {
			setConcernedInputsRequired(true)
		} else {
			setConcernedInputsRequired(false)
		}

		onFixError &&
			errors &&
			onFixError(
				errors.filter(
					(error) =>
						![
							addressOptionInput.name,
							addressFieldInput.name,
							addressLotNumberInput.name
						].includes(error)
				)
			)
	}, [isConcernedInfosRequired, addressOptionInput])

	useEffect(() => {
		if (isConcernedInfosRequired == true) {
			setConcernedInputsRequired(true)
		} else {
			setConcernedInputsRequired(false)
		}
	}, [])

	const setConcernedInputsRequired = (value: boolean) => {
		if (isFieldRequired(addressFieldInput.name)) {
			// required addressFieldInput if addressOptionInput.value == REQUEST_CONFIGS_KEYS.researchType.address
			if (
				addressOptionInput.value == REQUEST_CONFIGS_KEYS.researchType.address
			) {
				setAddressFieldInput({
					...addressFieldInput,
					required: value
				})
			} else {
				setAddressFieldInput({
					...addressFieldInput,
					required: false
				})
			}
		}
		if (isFieldRequired(addressLotNumberInput.name)) {
			setAddressLotNumberInput({
				...addressLotNumberInput,
				required: value
			})
		}
	}

	const getPlaceholder = () => {
		switch (addressOptionInput.value) {
			case REQUEST_CONFIGS_KEYS.researchType.cadastralNumber:
				return '0000000'

			default:
				return ''
		}
	}

	const getAddressFreatures = (data) => {
		// console.log('IN getAddressFreatures')

		if (data?.length == 0) {
			onSetAddressFieldInput('')

			setDisplayWarning(true)
			// console.log('setDisplayWarning(true) IN getAddressFreatures')
			setDisplayAddressInfo(true)
		} else {
			setDisplayWarning(false)
			// console.log('setDisplayWarning(false) IN getAddressFreatures')
			setDisplayAddressInfo(false)
		}
	}

	const renderMapForm = () => {
		return (
			<>
				<MapForm
					addressLabel={
						addressOptionInput.value ==
						REQUEST_CONFIGS_KEYS.researchType.cadastralNumber
							? pageAssets?.request_form_addressInfoSection_addressOption_secondOption_label
							: pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label
					}
					value={
						addressOptionInput.value ==
						REQUEST_CONFIGS_KEYS.researchType.cadastralNumber
							? answers?.addressLotNumber?.toString()
							: answers?.addressField
					}
					addressRequired={
						addressFieldInput.required || addressLotNumberInput.required
					}
					hasError={
						addressOptionInput.value ==
						REQUEST_CONFIGS_KEYS.researchType.cadastralNumber
							? errors?.includes(addressLotNumberInput.name)
							: errors?.includes(addressFieldInput.name)
					}
					// showButtonUserLocation={showButtonUserLocation}
					onAddressInputChange={onSetAddressFieldInput}
					onAddressChange={onAddressChange}
					addressPlaceholder={getPlaceholder()}
					searchByCadastralNumber={
						addressOptionInput.value ==
						REQUEST_CONFIGS_KEYS.researchType.cadastralNumber
					}
					searchTypeForm={addressOptionInput.value}
					addressLabelNoWrapper
					getAddressFreatures={getAddressFreatures}
					onAddressValueChange={onAddressValueChange}
					disableLocationIcon={true}
				/>
			</>
		)
	}

	const clearAllFields = () => {
		// console.log('IN clearAllFields')
		setNbrOfAddress({ ...nbrOfAddress, value: '1' })

		setAddressLotNumberInput({ ...addressLotNumberInput, value: '' })
		setAddressFieldInput({ ...addressFieldInput, value: '' })
		setAddressVoiePublicInput({ ...addressVoiePublicInput, value: '' })
		setAddressCivicNumberInput({ ...addressCivicNumberInput, value: '' })
		setAddressVoiePublic2Input({ ...addressVoiePublic2Input, value: '' })
		setAddressCivicNumber2Input({ ...addressCivicNumber2Input, value: '' })
		setAddressVoiePublic3Input({ ...addressVoiePublic3Input, value: '' })
		setAddressCivicNumber3Input({ ...addressCivicNumber3Input, value: '' })
		setAddressAdditionalInfosInput({
			...addressAdditionalInfosInput,
			value: ''
		})
	}

	const onAddressValueChange = (value: string) => {
		// console.log('IN onAddressValueChange')
		setDisplayAddressInfo(false)

		clearAllFields()

		if (
			addressOptionInput.value ==
			REQUEST_CONFIGS_KEYS.researchType.cadastralNumber
		) {
			setTMPAddressLotNumber(value)
		}
	}

	const onAddressChange = (address: AddressInfo) => {
		getAddressByCoordinates(address.coordinates).then((res) => {
			const data = res?.data?.features?.[0].properties

			// console.log('IN getAddressByCoordinates THEN ---------')
			setAddressFieldInput({
				...addressFieldInput,
				value: data.address
			})

			setAddressLotNumberInput({
				...addressLotNumberInput,
				value: data.cadastre
			})

			setAddressVoiePublicInput({
				...addressVoiePublicInput,
				value: data.odonym
			})

			setAddressCivicNumberInput({
				...addressCivicNumberInput,
				value: data.civic
			})

			setDisplayAddressInfo(true)

			if (!data.cadastre) {
				setDisplayWarning(true)
			} else {
				setDisplayWarning(false)
			}
		})
	}

	const isFieldRequired = (fieldName) => {
		if (requiredFields) {
			return requiredFields.includes(fieldName)
		}

		return true
	}

	useEffect(() => {
		if (addressFieldInput.value != '' || addressLotNumberInput.value != '') {
			// console.log('in USE EFFECT setDisplayAddressInfo(true)')
			setDisplayAddressInfo(true)
		} else {
			// console.log('in USE EFFECT setDisplayAddressInfo(false)')
			setDisplayAddressInfo(false)
			setDisplayWarning(false)
		}
	}, [addressFieldInput, addressLotNumberInput])

	const renderLotNumberColumn = (isDisabled: boolean) => {
		if (addressOptionInput.value == REQUEST_CONFIGS_KEYS.researchType.address) {
			return (
				<span>
					<TextInput
						id={addressLotNumberInput.name}
						value={addressLotNumberInput.value}
						onChange={onSetAddressLotNumberInput}
						hasError={
							!isDisabled && errors?.includes(addressLotNumberInput.name)
						}
						ref={addressLotNumberInput.ref as RefObject<HTMLInputElement>}
						classWrapper={classes.reducedMarginBottom}
						disabled={isDisabled}
					/>
				</span>
			)
		}

		return (
			<span className={classes.labelLotNumber}>
				{addressLotNumberInput.value}
			</span>
		)
	}

	const getNotificationText = () => {
		if (
			addressOptionInput.value ==
			REQUEST_CONFIGS_KEYS.researchType.cadastralNumber
		) {
			if (displayWarning) {
				return pageAssets?.request_form_addressVoiePublicSection_the_value_entered_not_found_byLot
			}

			return pageAssets?.request_form_addressVoiePublicSection_please_validate_the_following_info_byLot
		}

		if (addressOptionInput.value == REQUEST_CONFIGS_KEYS.researchType.address) {
			if (displayWarning) {
				return pageAssets?.request_form_addressVoiePublicSection_the_value_entered_not_found_byAddress
			}

			return pageAssets?.request_form_addressVoiePublicSection_please_validate_the_following_info_byAddress
		}

		return ''
	}

	return (
		<>
			{/* {console.log('nbrOfAddress.value', nbrOfAddress.value)}
			{console.log('cadastre', addressLotNumberInput.value)}
			{console.log('addressOptionInput.value', addressOptionInput.value)}
			{console.log('displayAddressInfo', displayAddressInfo)}
			{console.log('-------------------------------------------------', displayWarning)} */}
			<div className={classes.addressVoiePublicSection}>
				<div className={classes.inputGroup}>
					{radioListAddressOption.length > 1 && (
						<div className={classes.radioStyle}>
							<RadioInput
								name={addressOptionInput.name}
								// label={undefined}
								value={addressOptionInput.value}
								list={radioListAddressOption}
								direction={Direction.horizontal}
								hasError={errors!.includes(addressOptionInput.name)}
								onChange={onsetaddressOptionInput}
								ref={addressOptionInput.ref as RefObject<HTMLInputElement>}
							/>
						</div>
					)}
					{renderMapForm()}
				</div>

				<Collapse
					isOpened={
						// true
						displayAddressDetails && displayAddressInfo
					}
				>
					{(displayInfo || displayWarning) && (
						<Notification type={'info'} text={getNotificationText()} />
					)}

					{addressOptionInput.value ==
						REQUEST_CONFIGS_KEYS.researchType.address && (
						<div className={classes.lotNumberInput}>
							<TextInput
								id={addressLotNumberInput.name}
								label={addressLotNumberInput.label}
								value={addressLotNumberInput.value}
								onChange={onSetAddressLotNumberInput}
								hasError={errors?.includes(addressLotNumberInput.name)}
								ref={addressLotNumberInput.ref as RefObject<HTMLInputElement>}
								required={addressLotNumberInput.required}
							/>
						</div>
					)}

					<div className={classes.container}>
						<div
							className={joinClasses([
								classes.fieldsContainer,
								classes.fieldsContainerFirst
							])}
						>
							<span>
								{pageAssets?.request_form_addressVoiePublicSection_lot_number}
							</span>

							<span>
								{pageAssets?.request_form_addressVoiePublicSection_public_road}
							</span>

							<span>
								{pageAssets?.request_form_addressVoiePublicSection_civic_number}
							</span>

							<span></span>
						</div>
						<hr />

						{/* //!!!--------first row search by LOT--------- */}
						{addressOptionInput.value ==
							REQUEST_CONFIGS_KEYS.researchType.cadastralNumber && (
							<>
								<div className={classes.fieldsContainer}>
									{renderLotNumberColumn(false)}

									<TextInput
										id={addressVoiePublicInput.name}
										value={addressVoiePublicInput.value}
										onChange={onSetAddressVoiePublicInput}
										hasError={errors?.includes(addressVoiePublicInput.name)}
										ref={
											addressVoiePublicInput.ref as RefObject<HTMLInputElement>
										}
										classWrapper={classes.reducedMarginBottom}
									/>

									<TextInput
										id={addressCivicNumberInput.name}
										value={addressCivicNumberInput.value}
										onChange={onSetAddressCivicNumberInput}
										hasError={errors?.includes(addressCivicNumberInput.name)}
										ref={
											addressCivicNumberInput.ref as RefObject<HTMLInputElement>
										}
										classWrapper={classes.reducedMarginBottom}
									/>

									<div></div>
								</div>

								<hr />
							</>
						)}
						{/* //!!!----------------- */}

						{/* //!!!--------first row search by ADDRESS--------- */}
						{addressOptionInput.value ==
							REQUEST_CONFIGS_KEYS.researchType.address && (
							<>
								<div className={classes.fieldsContainer}>
									{renderLotNumberColumn(false)}

									<span className={classes.labelLotNumber}>
										{addressVoiePublicInput.value}
									</span>

									<span className={classes.labelLotNumber}>
										{addressCivicNumberInput.value}
									</span>

									<div></div>
								</div>

								<hr />
							</>
						)}
						{/* //!!!----------------- */}

						{/* // --------second row search by field--------- */}

						{parseInt(nbrOfAddress.value) >= 2 && (
							<>
								<div className={classes.fieldsContainer}>
									{renderLotNumberColumn(true)}

									<TextInput
										id={addressVoiePublic2Input.name}
										value={addressVoiePublic2Input.value}
										onChange={onSetAddressVoiePublic2Input}
										hasError={errors?.includes(addressVoiePublic2Input.name)}
										ref={
											addressVoiePublic2Input.ref as RefObject<HTMLInputElement>
										}
										classWrapper={classes.reducedMarginBottom}
									/>

									<TextInput
										id={addressCivicNumber2Input.name}
										value={addressCivicNumber2Input.value}
										onChange={onSetAddressCivicNumber2Input}
										hasError={errors?.includes(addressCivicNumber2Input.name)}
										ref={
											addressCivicNumber2Input.ref as RefObject<HTMLInputElement>
										}
										classWrapper={classes.reducedMarginBottom}
									/>

									<div>
										{parseInt(nbrOfAddress.value) == 2 && (
											<span
												className={classes.closeIcon}
												onClick={() => onRemoveNbrOfAddress(2)}
											>
												<Icon src={closeIcon} />
											</span>
										)}
									</div>
								</div>

								<hr />
							</>
						)}

						{/* --------third row search by civic number--------- */}
						{parseInt(nbrOfAddress.value) >= 3 && (
							<>
								<div className={classes.fieldsContainer}>
									{renderLotNumberColumn(true)}

									<TextInput
										id={addressVoiePublic3Input.name}
										value={addressVoiePublic3Input.value}
										onChange={onSetAddressVoiePublic3Input}
										hasError={errors?.includes(addressVoiePublic3Input.name)}
										ref={
											addressVoiePublic3Input.ref as RefObject<HTMLInputElement>
										}
										classWrapper={classes.reducedMarginBottom}
									/>

									<TextInput
										id={addressCivicNumber3Input.name}
										value={addressCivicNumber3Input.value}
										onChange={onSetAddressCivicNumber3Input}
										hasError={errors?.includes(addressCivicNumber3Input.name)}
										ref={
											addressCivicNumber3Input.ref as RefObject<HTMLInputElement>
										}
										classWrapper={classes.reducedMarginBottom}
									/>

									<div>
										{parseInt(nbrOfAddress.value) == 3 && (
											<span
												className={classes.closeIcon}
												onClick={() => onRemoveNbrOfAddress(3)}
											>
												<Icon src={closeIcon} />
											</span>
										)}
									</div>
								</div>
								<hr />
							</>
						)}

						{/* --------additional infos row --------- */}

						{parseInt(nbrOfAddress.value) >= 4 && (
							<>
								<div className={classes.fieldsContainerAdditional}>
									{renderLotNumberColumn(true)}

									<TextAreaInput
										id={addressAdditionalInfosInput.name}
										label={addressAdditionalInfosInput.label}
										value={addressAdditionalInfosInput.value}
										onChange={onSetAddressAdditionalInfosInput}
										hasError={errors?.includes(
											addressAdditionalInfosInput.name
										)}
										ref={
											addressAdditionalInfosInput.ref as RefObject<HTMLTextAreaElement>
										}
										rows={4}
										maxCharacters={500}
										placeholder={
											pageAssets?.request_form_addressInfoSection_additional_infos_placeholder
										}
									/>

									<div>
										{parseInt(nbrOfAddress.value) == 4 && (
											<span
												className={classes.closeIcon}
												onClick={() => onRemoveNbrOfAddress(4)}
											>
												<Icon src={closeIcon} />
											</span>
										)}
									</div>
								</div>
								<hr />
							</>
						)}

						{parseInt(nbrOfAddress.value) < 4 && (
							<a
								style={{ margin: '5px 0px 10px' }}
								onClick={() => onAddNbrOfAddress()}
								className={classes.button}
							>
								{pageAssets?.request_form_addressVoiePublicSection_add_address}
							</a>
						)}
					</div>
				</Collapse>
			</div>
		</>
	)
}

export default AddressVoiePublicSection
