import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RequestFormPDFProps,
	RequestInputs,
	SelectList,
	RequestInputFiles
} from '@utils/request'
import { joinClasses, makeClasses } from '@utils/styles'
import requestForm from './request.form'
import { useAppSelector } from '@services/store'
import { Collapse } from 'react-collapse'
import {
	requestCategories,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import SelectInput from './inputs/select.inputs'
import TextInput from './inputs/text.inputs'
import RequestFiles from '@components/ui/requestFiles'
import Text from '@components/ui/text'
import { Colors } from '@utils/css-variables'

const ReplacementOilHeatingSystemForm: FC<RequestFormPDFProps> = ({
	errors,
	toPrint,
	hasFileRequiredError,
	inputs,
	inputFiles,
	subSteps,
	formPosition,
	isSummaryStep,
	steps,
	filesInputs,
	onSetInputFiles,
	onSetSubSteps,
	onSetHasFileRequiredError,
	onSetInputs,
	onFixError,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsErrorsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	type Classes = {
		fileHelperSection: string
		fileContainer: string
	}

	const classes: Classes = makeClasses({
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		}
	})

	useEffect(() => {
		onSetSubSteps({
			map: true,
			details_1: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.MAP,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.MAP,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const [homeTypeInput, setHomeTypeInput] = useState<RequestInputs>({
		name: 'accommodationType',
		value: `${answers.accommodationType || ''}`,
		label:
			pageAssets?.request_form_replacementOilHeatingSystem_accommodationType_label,
		labelKey:
			'request_form_replacementOilHeatingSystem_accommodationType_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [systemTypeInput, setSystemTypeInput] = useState<RequestInputs>({
		name: 'fuelSystemReplacedType',
		value: answers.fuelSystemReplacedType || '',
		label:
			pageAssets?.request_form_replacementOilHeatingSystem_fuelSystemReplacedType_label,
		labelKey:
			'request_form_replacementOilHeatingSystem_fuelSystemReplacedType_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [convertedAccommodationListInput, setConvertedAccommodationListInput] =
		useState<RequestInputs>({
			name: 'convertedAccommodationList',
			value: answers.convertedAccommodationList || '',
			label:
				pageAssets?.request_form_replacementOilHeatingSystem_convertedAccommodationList_label,
			labelKey:
				'request_form_replacementOilHeatingSystem_convertedAccommodationList_label',
			required: answers.accommodationType
				? [
						REQUEST_CONFIGS_KEYS.homeType.duplex,
						REQUEST_CONFIGS_KEYS.homeType.triplex,
						REQUEST_CONFIGS_KEYS.homeType.building
				  ].includes(String(answers.accommodationType))
				: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [numberOfConvertedHouseInput, setNumberOfConvertedHouseInput] =
		useState<RequestInputs>({
			name: 'numberOfAccommodationConverted',
			label:
				pageAssets?.request_form_replacementOilHeatingSystem_numberOfAccommodationConverted_label,
			labelKey:
				'request_form_replacementOilHeatingSystem_numberOfAccommodationConverted_label',
			value: `${answers.numberOfAccommodationConverted || ''}`,
			required: answers.accommodationType
				? [
						REQUEST_CONFIGS_KEYS.homeType.duplex,
						REQUEST_CONFIGS_KEYS.homeType.triplex,
						REQUEST_CONFIGS_KEYS.homeType.building
				  ].includes(String(answers.accommodationType))
				: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [alternativeHeatingSystemInput, setAlternativeHeatingSystemInput] =
		useState<RequestInputs>({
			name: 'heatingReplacementSystemType',
			value: answers.heatingReplacementSystemType || '',
			label:
				pageAssets?.request_form_replacementOilHeatingSystem_heatingReplacementSystemType_label,
			labelKey:
				'request_form_replacementOilHeatingSystem_heatingReplacementSystemType_label',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [contactorLicenseNumberInput, setContactorLicenseNumberInput] =
		useState<RequestInputs>({
			name: 'contactorLicenseNumber',
			value: answers.contactorLicenseNumber || '',
			label:
				pageAssets?.request_form_replacementOilHeatingSystem_contactorLicenseNumber_label,
			labelKey:
				'request_form_replacementOilHeatingSystem_contactorLicenseNumber_label',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [
		subventionTotalValueRequestedInput,
		setSubventionTotalValueRequestedInput
	] = useState<RequestInputs>({
		name: 'subventionTotalValueRequested',
		label:
			pageAssets?.request_form_replacementOilHeatingSystem_subventionTotalValueRequested_label,
		labelKey:
			'request_form_replacementOilHeatingSystem_subventionTotalValueRequested_label',
		value: `${answers.subventionTotalValueRequested || ''}`,
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [
		buildingAddressSameHasApplicantInput,
		setBuildingAddressSameHasApplicantInput
	] = useState<RequestInputs>({
		name: 'buildingAddressSameHasApplicant',
		value: 'true',
		label: '',
		labelKey: '',
		required: false
	})

	const [buildingAddressInput, setBuildingAddressInput] =
		useState<RequestInputs>({
			name: 'buildingAddress',
			value: location.address,
			label: '',
			labelKey: '',
			required: false
		})

	const [buildingPostalCodeInput, setBuildingPostalCodeInput] =
		useState<RequestInputs>({
			name: 'buildingPostalCode',
			value: location.postalCode,
			label: '',
			labelKey: '',
			required: false
		})

	const [buildingCityInput, setBuildingCityInput] = useState<RequestInputs>({
		name: 'buildingCity',
		value: location.city,
		label: '',
		labelKey: '',
		required: false
	})

	const homeTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_homeType_singleFamily,
			value: REQUEST_CONFIGS_KEYS.homeType.singleFamily,
			key: 'select_homeType_singleFamily'
		},
		{
			label: pageAssets?.select_homeType_twinned,
			value: REQUEST_CONFIGS_KEYS.homeType.twinned,
			key: 'select_homeType_twinned'
		},
		{
			label: pageAssets?.select_homeType_duplex,
			value: REQUEST_CONFIGS_KEYS.homeType.duplex,
			key: 'select_homeType_duplex'
		},
		{
			label: pageAssets?.select_homeType_mobile,
			value: REQUEST_CONFIGS_KEYS.homeType.mobile,
			key: 'select_homeType_mobile'
		},
		{
			label: pageAssets?.select_homeType_triplex,
			value: REQUEST_CONFIGS_KEYS.homeType.triplex,
			key: 'select_homeType_triplex'
		},
		{
			label: pageAssets?.select_homeType_in_a_row,
			value: REQUEST_CONFIGS_KEYS.homeType.inRows,
			key: 'select_homeType_in_a_row'
		},
		{
			label: pageAssets?.select_homeType_building,
			value: REQUEST_CONFIGS_KEYS.homeType.building,
			key: 'select_homeType_building'
		}
	]

	const systemTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_systemType_oil_system,
			value: REQUEST_CONFIGS_KEYS.systemType.oil,
			key: 'select_systemType_oil_system'
		},
		{
			label: pageAssets?.select_systemType_dualEnergy_system,
			value: REQUEST_CONFIGS_KEYS.systemType.biEnergetic,
			key: 'select_systemType_dualEnergy_system'
		}
	]

	const alternativeHeatingSystemList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_alternativeHeatingSystem_electric,
			value: REQUEST_CONFIGS_KEYS.alternativeHeatingSystem.electric,
			key: 'select_alternativeHeatingSystem_electric'
		},
		{
			label: pageAssets?.select_alternativeHeatingSystem_aerothermal,
			value: REQUEST_CONFIGS_KEYS.alternativeHeatingSystem.aerothermal,
			key: 'select_alternativeHeatingSystem_aerothermal'
		},
		{
			label: pageAssets?.select_alternativeHeatingSystem_solar,
			value: REQUEST_CONFIGS_KEYS.alternativeHeatingSystem.solar,
			key: 'select_alternativeHeatingSystem_solar'
		},
		{
			label: pageAssets?.select_alternativeHeatingSystem_geothermal,
			value: REQUEST_CONFIGS_KEYS.alternativeHeatingSystem.geothermal,
			key: 'select_alternativeHeatingSystem_geothermal'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value)?.label
	}

	const [oilBill, setOilBill] = useState<RequestInputFiles>({
		name: 'oilBillInput',
		label: pageAssets?.request_form_oil_bill,
		labelKey: 'request_form_oil_bill',
		description: pageAssets?.request_page_oil_bill_subtitle,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'oilBillInput')?.files || []
		),
		required: true,
		category: requestCategories.proofOfPurchase,
		numberFilesRequired: 1
	})

	const [electricityBill, setElectricityBill] = useState<RequestInputFiles>({
		name: 'electricityBillInput',
		label: pageAssets?.request_form_electricity_bill,
		labelKey: 'request_form_electricity_bill',
		description: pageAssets?.request_form_electricity_bill_subtitle,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'electricityBillInput')?.files || []
		),
		required: true,
		category: requestCategories.proofOfPurchase,
		numberFilesRequired: 1
	})

	const [heatingSystemBill, setHeatingSystemBill] = useState<RequestInputFiles>(
		{
			name: 'heatingSystemBillInput',
			label: pageAssets?.request_form_heating_system_bill,
			labelKey: 'request_form_heating_system_bill',
			description: pageAssets?.request_form_heating_system_bill_subtitle,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'heatingSystemBillInput')?.files ||
					[]
			),
			required: true,
			category: requestCategories.proofOfPurchase,
			numberFilesRequired: 1,
			numberMaxFiles: 5
		}
	)

	const [firstContractorBill, setFirstContractorBill] =
		useState<RequestInputFiles>({
			name: 'firstContractorBillInput',
			label: pageAssets?.request_form_first_contractor_bill,
			labelKey: 'request_form_first_contractor_bill',
			description: pageAssets?.request_form_first_contractor_bill_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'firstContractorBillInput')
					?.files || []
			),
			required: true,
			category: requestCategories.proofOfPurchase,
			numberFilesRequired: 1
		})

	const [secondContractorBill, setSecondContractorBill] =
		useState<RequestInputFiles>({
			name: 'secondContractorBillInput',
			label: pageAssets?.request_form_second_contractor_bill,
			labelKey: 'request_form_second_contractor_bill',
			description: pageAssets?.request_form_second_contractor_bill_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'secondContractorBillInput')
					?.files || []
			),
			required: true,
			category: requestCategories.proofOfPurchase,
			numberFilesRequired: 1
		})

	const onSelectHomeTypeInput = (value: string) => {
		setHomeTypeInput({ ...homeTypeInput, value })

		if (shouldNbrOfConvHouseInputAndConvAccListInputDisplay(value)) {
			setNumberOfConvertedHouseInput({
				...numberOfConvertedHouseInput,
				required: true
			})
			setConvertedAccommodationListInput({
				...convertedAccommodationListInput,
				required: true
			})
		} else {
			setNumberOfConvertedHouseInput({
				...numberOfConvertedHouseInput,
				value: '',
				required: false
			})
			setConvertedAccommodationListInput({
				...convertedAccommodationListInput,
				value: '',
				required: false
			})
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, homeTypeInput.name))
	}

	const onSetSystemTypeInput = (value: string) => {
		setSystemTypeInput({ ...systemTypeInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, systemTypeInput.name))
	}

	const onSetNumberOfConvertedHouseInput = (value: string) => {
		setNumberOfConvertedHouseInput({ ...numberOfConvertedHouseInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, numberOfConvertedHouseInput.name)
		)
	}

	const onSetConvertedAccommodationListInput = (value: string) => {
		setConvertedAccommodationListInput({
			...convertedAccommodationListInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				convertedAccommodationListInput.name
			)
		)
	}

	const onSetSubventionTotalValueRequestedInput = (value: string) => {
		setSubventionTotalValueRequestedInput({
			...subventionTotalValueRequestedInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				subventionTotalValueRequestedInput.name
			)
		)
	}

	const onSetContactorLicenseNumberInput = (value: string) => {
		setContactorLicenseNumberInput({ ...contactorLicenseNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, contactorLicenseNumberInput.name)
		)
	}

	const onSetAlternativeHeatingSystemInput = (value: string) => {
		setAlternativeHeatingSystemInput({
			...alternativeHeatingSystemInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, alternativeHeatingSystemInput.name)
		)
	}

	const onSelectFileOilBill = (files: Set<File>) => {
		setOilBill({
			...oilBill,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, oilBill.name))
	}

	const onSelectFileElectricityBill = (files: Set<File>) => {
		setElectricityBill({
			...electricityBill,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, electricityBill.name))
	}

	const onSelectFileHeatingSystemBill = (files: Set<File>) => {
		setHeatingSystemBill({
			...heatingSystemBill,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, heatingSystemBill.name))
	}

	const onSelectFirstContractorBill = (files: Set<File>) => {
		setFirstContractorBill({
			...firstContractorBill,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, firstContractorBill.name))
	}

	const onSelectSecondContractorBill = (files: Set<File>) => {
		setSecondContractorBill({
			...secondContractorBill,
			files
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, secondContractorBill.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, homeTypeInput))
	}, [homeTypeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, systemTypeInput))
	}, [systemTypeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, numberOfConvertedHouseInput))
	}, [numberOfConvertedHouseInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, alternativeHeatingSystemInput))
	}, [alternativeHeatingSystemInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, convertedAccommodationListInput)
		)
	}, [convertedAccommodationListInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, contactorLicenseNumberInput))
	}, [contactorLicenseNumberInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, subventionTotalValueRequestedInput)
		)
	}, [subventionTotalValueRequestedInput])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, oilBill))
	}, [oilBill])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, electricityBill))
	}, [electricityBill])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, heatingSystemBill))
	}, [heatingSystemBill])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, firstContractorBill)
		)
	}, [firstContractorBill])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, secondContractorBill)
		)
	}, [secondContractorBill])

	useEffect(() => {
		if (
			location.coordinates.latitude ==
				authUser?.profile?.address.coordinates?.latitude &&
			location.coordinates.longitude ==
				authUser?.profile?.address.coordinates?.longitude
		) {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'true'
			})
		} else {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'false'
			})
		}
	}, [location.coordinates])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, buildingAddressSameHasApplicantInput)
		)
	}, [buildingAddressSameHasApplicantInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingAddressInput))
	}, [buildingAddressInput])

	useEffect(() => {
		setBuildingAddressInput((prevState) => ({
			...prevState,
			value: location.address
		}))
	}, [location.address])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingPostalCodeInput))
	}, [buildingPostalCodeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingCityInput))
	}, [buildingCityInput])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// oilBill input
		if (!!answers.oilBillInput && oilBill.files.size == 0) {
			getFilesFromStore('oilBillInput', answers).then((result: Set<File>) => {
				setOilBill({
					...oilBill,
					files: result
				})
			})
		}
		// electricityBill input
		if (!!answers.electricityBillInput && electricityBill.files.size == 0) {
			getFilesFromStore('electricityBillInput', answers).then(
				(result: Set<File>) => {
					setElectricityBill({
						...electricityBill,
						files: result
					})
				}
			)
		}
		// heatingSystemBill input
		if (!!answers.heatingSystemBillInput && heatingSystemBill.files.size == 0) {
			getFilesFromStore('heatingSystemBillInput', answers).then(
				(result: Set<File>) => {
					setHeatingSystemBill({
						...heatingSystemBill,
						files: result
					})
				}
			)
		}

		// firstContractorBill input
		if (
			!!answers.firstContractorBillInput &&
			firstContractorBill.files.size == 0
		) {
			getFilesFromStore('firstContractorBillInput', answers).then(
				(result: Set<File>) => {
					setFirstContractorBill({
						...firstContractorBill,
						files: result
					})
				}
			)
		}

		// secondContractorBill input
		if (
			!!answers.secondContractorBillInput &&
			secondContractorBill.files.size == 0
		) {
			getFilesFromStore('secondContractorBillInput', answers).then(
				(result: Set<File>) => {
					setSecondContractorBill({
						...secondContractorBill,
						files: result
					})
				}
			)
		}
	}, [])

	const shouldNbrOfConvHouseInputAndConvAccListInputDisplay = (
		value
	): boolean => {
		return [
			REQUEST_CONFIGS_KEYS.homeType.duplex,
			REQUEST_CONFIGS_KEYS.homeType.triplex,
			REQUEST_CONFIGS_KEYS.homeType.building
		].includes(String(value))
	}

	type fileSectionProps = {
		isSummary?: boolean
	}

	const FilesSection: FC<fileSectionProps> = ({ isSummary }) => (
		<>
			<RequestFiles
				subtitle={oilBill?.label}
				required
				files={oilBill.files}
				hasError={hasFileRequiredError}
				removeSectionMarginTop
				onSetHasError={onSetHasFileRequiredError}
				onSetFiles={onSelectFileOilBill}
				formPosition={formPosition}
				isSummary={isSummary}
				maxFiles={isSummary ? undefined : oilBill.numberFilesRequired}
			>
				<></>
			</RequestFiles>

			<RequestFiles
				subtitle={electricityBill?.label}
				required
				files={electricityBill.files}
				hasError={hasFileRequiredError}
				removeSectionMarginTop
				onSetHasError={onSetHasFileRequiredError}
				onSetFiles={onSelectFileElectricityBill}
				formPosition={formPosition}
				isSummary={isSummary}
				maxFiles={isSummary ? undefined : electricityBill.numberFilesRequired}
			>
				<></>
			</RequestFiles>

			<RequestFiles
				subtitle={heatingSystemBill?.label}
				required
				files={heatingSystemBill.files}
				hasError={hasFileRequiredError}
				removeSectionMarginTop
				onSetHasError={onSetHasFileRequiredError}
				onSetFiles={onSelectFileHeatingSystemBill}
				formPosition={formPosition}
				isSummary={isSummary}
				maxFiles={isSummary ? undefined : 5}
				minFiles={isSummary ? undefined : heatingSystemBill.numberFilesRequired}
			>
				<></>
			</RequestFiles>

			<RequestFiles
				subtitle={firstContractorBill?.label}
				required
				files={firstContractorBill.files}
				hasError={hasFileRequiredError}
				removeSectionMarginTop
				onSetHasError={onSetHasFileRequiredError}
				onSetFiles={onSelectFirstContractorBill}
				maxFiles={firstContractorBill.numberFilesRequired}
				isSummary={isSummary}
			>
				<></>
			</RequestFiles>

			<RequestFiles
				subtitle={secondContractorBill?.label}
				required
				files={secondContractorBill.files}
				hasError={hasFileRequiredError}
				removeSectionMarginTop
				onSetHasError={onSetHasFileRequiredError}
				onSetFiles={onSelectSecondContractorBill}
				minFiles={secondContractorBill.numberFilesRequired}
				isSummary={isSummary}
			>
				<></>
			</RequestFiles>
		</>
	)

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				<div>
					<a
						style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
						className={joinClasses([
							pageUtils.classes.btn,
							pageUtils.classes.button,
							pageUtils.classes.buttonOutline
						])}
						onClick={() =>
							backToForm(steps?.form!, REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1)
						}
					>
						{pageAssets?.label_modify}
					</a>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_grant_details}
					</h4>
				</div>

				<h5 className={pageUtils.classes.h5}>
					{formatStrapiText(
						pageAssets?.request_from_replacementOilHeatingSystem_first_subtitle
					)}
				</h5>

				<div>
					<strong>{pageAssets[homeTypeInput.labelKey]}</strong>
					<br />
					<span className={pageUtils.classes.answer}>
						{getLabelFromList(homeTypeList, answers.accommodationType)}
					</span>
				</div>

				{shouldNbrOfConvHouseInputAndConvAccListInputDisplay(
					answers.accommodationType
				) && (
					<>
						<div>
							<strong>
								{pageAssets[numberOfConvertedHouseInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.numberOfAccommodationConverted}
							</span>
						</div>

						<div>
							<strong>
								{pageAssets[convertedAccommodationListInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.convertedAccommodationList}
							</span>
						</div>
					</>
				)}

				<h5 className={pageUtils.classes.h5}>
					{formatStrapiText(
						pageAssets?.request_from_replacementOilHeatingSystem_second_subtitle
					)}
				</h5>

				<div>
					<strong>{pageAssets[systemTypeInput.labelKey]}</strong>
					<br />
					<span className={pageUtils.classes.answer}>
						{getLabelFromList(systemTypeList, answers.fuelSystemReplacedType)}
					</span>
				</div>

				<div>
					<strong>{pageAssets[alternativeHeatingSystemInput.labelKey]}</strong>
					<br />
					<span className={pageUtils.classes.answer}>
						{getLabelFromList(
							alternativeHeatingSystemList,
							answers.heatingReplacementSystemType
						)}
					</span>
				</div>

				<div>
					<strong>{pageAssets[contactorLicenseNumberInput.labelKey]}</strong>
					<br />
					<span className={pageUtils.classes.answer}>
						{answers.contactorLicenseNumber}
					</span>
				</div>

				<div>
					<strong>
						{pageAssets[subventionTotalValueRequestedInput.labelKey]}
					</strong>
					<br />
					<span className={pageUtils.classes.answer}>
						{answers.subventionTotalValueRequested}
					</span>
				</div>

				<div style={{ marginTop: '40px' }}>
					<a
						className={joinClasses([
							pageUtils.classes.btn,
							pageUtils.classes.button,
							pageUtils.classes.buttonOutline
						])}
						onClick={() =>
							backToForm(steps?.form!, REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS)
						}
						style={{ float: 'right' }}
					>
						{pageAssets?.label_modify}
					</a>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_required_documents}
					</h4>
				</div>

				<FilesSection isSummary />
			</section>
		)
	}

	return (
		<>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
			>
				<section>
					<h4 className={pageUtils.classes.h4}>
						{formatStrapiText(pageAssets?.request_form_grant_details)}
					</h4>

					<h5 className={pageUtils.classes.h5}>
						{formatStrapiText(
							pageAssets?.request_from_replacementOilHeatingSystem_first_subtitle
						)}
					</h5>

					<SelectInput
						id={homeTypeInput.name}
						label={homeTypeInput.label}
						value={homeTypeInput.value}
						list={homeTypeList}
						required
						hasError={errors.includes(homeTypeInput.name)}
						onChange={onSelectHomeTypeInput}
						ref={homeTypeInput.ref as RefObject<HTMLSelectElement>}
					/>

					{shouldNbrOfConvHouseInputAndConvAccListInputDisplay(
						homeTypeInput.value
					) && (
						<>
							<div className={pageUtils.classes.fieldsContainer}>
								<TextInput
									type="number"
									id={numberOfConvertedHouseInput.name}
									label={numberOfConvertedHouseInput.label}
									value={numberOfConvertedHouseInput.value}
									onChange={onSetNumberOfConvertedHouseInput}
									hasError={errors.includes(numberOfConvertedHouseInput.name)}
									required
									min="0"
									ref={
										numberOfConvertedHouseInput.ref as RefObject<HTMLInputElement>
									}
									onKeyPress={(event) => {
										const charCode = event.which ? event.which : event.keyCode
										const char = String.fromCharCode(charCode)

										if (!/^\d$/.test(char)) {
											event.preventDefault()
										}
									}}
								/>
							</div>

							<TextInput
								id={convertedAccommodationListInput.name}
								label={convertedAccommodationListInput.label}
								value={convertedAccommodationListInput.value}
								onChange={onSetConvertedAccommodationListInput}
								hasError={errors.includes(convertedAccommodationListInput.name)}
								required
								ref={
									convertedAccommodationListInput.ref as RefObject<HTMLInputElement>
								}
							/>
						</>
					)}

					<h5 className={pageUtils.classes.h5}>
						{formatStrapiText(
							pageAssets?.request_from_replacementOilHeatingSystem_second_subtitle
						)}
					</h5>

					<SelectInput
						id={systemTypeInput.name}
						label={systemTypeInput.label}
						value={systemTypeInput.value}
						list={systemTypeList}
						required
						hasError={errors.includes(systemTypeInput.name)}
						onChange={onSetSystemTypeInput}
						ref={systemTypeInput.ref as RefObject<HTMLSelectElement>}
					/>

					<SelectInput
						id={alternativeHeatingSystemInput.name}
						label={alternativeHeatingSystemInput.label}
						value={alternativeHeatingSystemInput.value}
						list={alternativeHeatingSystemList}
						required
						hasError={errors.includes(alternativeHeatingSystemInput.name)}
						onChange={onSetAlternativeHeatingSystemInput}
						ref={
							alternativeHeatingSystemInput.ref as RefObject<HTMLSelectElement>
						}
					/>

					<div className={pageUtils.classes.fieldsContainer}>
						<TextInput
							id={contactorLicenseNumberInput.name}
							label={contactorLicenseNumberInput.label}
							value={contactorLicenseNumberInput.value}
							onChange={onSetContactorLicenseNumberInput}
							hasError={errors.includes(contactorLicenseNumberInput.name)}
							required
							min="0"
							ref={
								contactorLicenseNumberInput.ref as RefObject<HTMLInputElement>
							}
						/>
					</div>

					<div className={pageUtils.classes.fieldsContainer}>
						<TextInput
							id={subventionTotalValueRequestedInput.name}
							type={'number'}
							label={subventionTotalValueRequestedInput.label}
							value={subventionTotalValueRequestedInput.value}
							onChange={onSetSubventionTotalValueRequestedInput}
							hasError={errors.includes(
								subventionTotalValueRequestedInput.name
							)}
							required
							isMoneyInput
							ref={
								subventionTotalValueRequestedInput.ref as RefObject<HTMLInputElement>
							}
						/>
					</div>
				</section>
			</Collapse>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				}
			>
				<section className={pageUtils.classes.sectionNoPadding}>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_required_documents}
					</h4>

					<span className={pageUtils.classes.fileInfo}>
						{pageAssets?.page_requestInfo_inputFileDesc}
					</span>

					<RequestFiles
						subtitle={oilBill?.label}
						required
						files={oilBill.files}
						hasError={errors.includes(oilBill.name)}
						removeSectionMarginTop
						onSetHasError={onSetHasFileRequiredError}
						onSetFiles={onSelectFileOilBill}
						subSteps={subSteps}
						maxFiles={oilBill.numberFilesRequired}
						description={oilBill?.description}
					></RequestFiles>

					<RequestFiles
						subtitle={electricityBill?.label}
						required
						files={electricityBill.files}
						hasError={errors.includes(electricityBill.name)}
						removeSectionMarginTop
						onSetHasError={onSetHasFileRequiredError}
						onSetFiles={onSelectFileElectricityBill}
						subSteps={subSteps}
						maxFiles={electricityBill.numberFilesRequired}
						description={electricityBill?.description}
					></RequestFiles>

					<RequestFiles
						subtitle={heatingSystemBill?.label}
						required
						files={heatingSystemBill.files}
						hasError={errors.includes(heatingSystemBill.name)}
						removeSectionMarginTop
						onSetHasError={onSetHasFileRequiredError}
						onSetFiles={onSelectFileHeatingSystemBill}
						subSteps={subSteps}
						minFiles={heatingSystemBill.numberFilesRequired}
						maxFiles={heatingSystemBill.numberMaxFiles}
						description={heatingSystemBill?.description}
					></RequestFiles>

					<RequestFiles
						subtitle={firstContractorBill?.label}
						required
						files={firstContractorBill.files}
						hasError={errors.includes(firstContractorBill.name)}
						removeSectionMarginTop
						onSetHasError={onSetHasFileRequiredError}
						onSetFiles={onSelectFirstContractorBill}
						subSteps={subSteps}
						minFiles={firstContractorBill.numberFilesRequired}
						maxFiles={firstContractorBill.numberFilesRequired}
						description={firstContractorBill?.description}
					></RequestFiles>

					<RequestFiles
						subtitle={secondContractorBill?.label}
						required
						files={secondContractorBill.files}
						hasError={errors.includes(secondContractorBill.name)}
						removeSectionMarginTop
						onSetHasError={onSetHasFileRequiredError}
						onSetFiles={onSelectSecondContractorBill}
						subSteps={subSteps}
						minFiles={secondContractorBill.numberFilesRequired}
						maxFiles={secondContractorBill.numberFilesRequired}
						description={secondContractorBill?.description}
					></RequestFiles>
				</section>
			</Collapse>
		</>
	)
}

export default ReplacementOilHeatingSystemForm
